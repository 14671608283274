
import Vue from "vue";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import HtmlEditor from "@/components/HtmlEditor";

@Component({
    components: { HtmlEditor }
})
export default class MonthlyReportDialog extends Vue {
    @PropSync("visible", { type: Boolean, required: true })
    valueSync!: boolean;

    @Prop({ type: String, required: true })
    public buttonSignature!: string;

    @Prop({ type: String, required: true })
    public header!: string;

    @Prop({ type: String, required: true })
    public readonly mainContent!: string;

    @Prop({ type: String, required: true })
    public readonly templateSignature!: string;

    public content = this.mainContent;
    public signature = this.templateSignature;

    saveContent(): void {
        this.$emit("save", this.content, this.signature);
    }

    close(): void {
        this.valueSync = false;
    }

    @Watch("valueSync")
    mainContentChanged(): void {
        this.content = this.mainContent;
        this.signature = this.templateSignature;
    }
}
