
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class SideFilter extends Vue {
    @Prop({ type: Boolean, default: false })
    locked!: boolean;
    @Prop({ type: String, default: "Filter" })
    label!: string;
    private isActive = false;

    get classes(): Array<string | null> {
        const classes = ["side-filter"];
        if (this.isActive) {
            classes.push("active");
        }
        return classes;
    }

    changeState(): void {
        this.isActive = !this.isActive;
        this.$emit("filterChangeState", this.isActive);
    }
}
