export type Rule = (
    v: string | number | Array<unknown> | null
) => string | boolean;
export interface RulesSettings {
    required?: boolean;
    length?: number;
    minLength?: number;
    maxLength?: number;
    email?: boolean;
    regex?: {
        pattern: RegExp;
        message: string;
    };
}

const propDefaultName = "Property";

function required(propName?: string): Rule {
    return (v) =>
        (!!v && getLength(v) > 0) ||
        `${propName ? propName : propDefaultName} is required`;
}

function length(length: number, propName?: string): Rule {
    return (v) => {
        const valueLength = v ? getLength(v) : 0;
        return (
            valueLength === length ||
            `${propName ?? propDefaultName} must be ${length} characters (now ${
                valueLength ?? 0
            })`
        );
    };
}

function minLength(length: number, propName?: string): Rule {
    return (v) => {
        const valueLength = v ? getLength(v) : 0;
        return (
            valueLength >= length ||
            `${
                propName ?? propDefaultName
            } must be more than ${length} characters (now ${valueLength})`
        );
    };
}

function maxLength(length: number, propName?: string): Rule {
    return (v) => {
        const valueLength = v ? getLength(v) : 0;
        return (
            valueLength <= length ||
            `${
                propName ?? propDefaultName
            } must be less than ${length} characters (now ${valueLength})`
        );
    };
}

function min(min: number, propName?: string): Rule {
    return (v) => {
        const numberValue = Number(v);
        return (
            numberValue >= min ||
            `${
                propName ?? propDefaultName
            } must be more than ${min} (now ${numberValue})`
        );
    };
}

function max(max: number, propName?: string): Rule {
    return (v) => {
        const numberValue = Number(v);
        return (
            numberValue <= max ||
            `${
                propName ?? propDefaultName
            } must be more than ${min} (now ${numberValue})`
        );
    };
}

function email(propName?: string): Rule {
    const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex(emailRegex, `${propName ?? "E-mail"} must be valid`);
}

function multipleEmails(propName?: string): Rule {
    const multipleEmailRegex =
        /^(([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/;

    return regex(multipleEmailRegex, `${propName ?? "E-mail"} must be valid`);
}

function regex(pattern: RegExp, message: string): Rule {
    return (v) => !v || typeof v !== "string" || pattern.test(v) || message;
}

function getLength(value: number | string | Array<unknown>): number {
    if (typeof value === "number") return value.toString().length;
    return value.length;
}

function getRules(settings: RulesSettings, propName?: string): Rule[] {
    const rules = [];
    if (settings.required) rules.push(required(propName));
    if (settings.length) rules.push(length(settings.length, propName));
    if (settings.minLength) rules.push(minLength(settings.minLength, propName));
    if (settings.maxLength) rules.push(maxLength(settings.maxLength, propName));
    if (settings.email) rules.push(email(propName));
    if (settings.regex)
        rules.push(regex(settings.regex.pattern, settings.regex.message));

    return rules;
}

export default {
    required,
    length,
    minLength,
    maxLength,
    min,
    max,
    email,
    multipleEmails,
    regex,
    getRules
};
