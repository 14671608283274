import { render, staticRenderFns } from "./SideFilter.html?vue&type=template&id=5179597e&scoped=true&"
import script from "./SideFilter.vue?vue&type=script&lang=ts&"
export * from "./SideFilter.vue?vue&type=script&lang=ts&"
import style0 from "./SideFilter.scss?vue&type=style&index=0&id=5179597e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5179597e",
  null
  
)

export default component.exports