
import { Component, Watch } from "vue-property-decorator";
import Vue from "vue";
import DynamicTitle from "@/components/DynamicTitle";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import { blacklistModule } from "@/store/modules/blacklist";
import { BlacklistGetters } from "@/store/modules/blacklist/getters";
import { BlacklistActions } from "@/store/modules/blacklist/actions";
import { PaginationResult } from "@/models/PaginationResult";
import { BlacklistFilter } from "@/models/blacklist/BlacklistFilter";
import { PaginationInfo } from "@/models/PaginationInfo";
import { Blacklist } from "@/models/blacklist/Blacklist";
import BlacklistUpsetDialog from "@/components/BlacklistUpsetDialog";

@Component({
    components: {
        DynamicTitle,
        ConfirmationDialog,
        BlacklistUpsetDialog
    },
    computed: {
        ...blacklistModule.mapGetters({
            paginationData: BlacklistGetters.PaginationData,
            isLoading: BlacklistGetters.IsLoading
        })
    },
    methods: {
        ...blacklistModule.mapActions({
            loadFilteredBlacklist:
                BlacklistActions.LoadBlacklistsWithPagination,
            deleteBlacklist: BlacklistActions.DeleteBlacklist
        })
    }
})
export default class BlacklistOverview extends Vue {
    protected readonly paginationData!: PaginationResult<Blacklist>;
    protected readonly isLoading!: boolean;

    protected headers = [
        { text: "Email", value: "email" },
        { text: "FirstName", value: "firstName" },
        { text: "LastName", value: "lastName" },
        { text: "Bearbeitet ", value: "lastModifiedDateTime" },
        {
            text: "Actions",
            value: "actions",
            sortable: false,
            filterable: false
        }
    ];

    protected options: {
        page?: number;
        itemsPerPage?: number;
        sortBy?: string[];
        sortDesc?: boolean[];
    } = {};

    protected deleteDialog = false;
    protected createDialog = false;
    protected updateDialog = false;

    protected blacklistFilter: BlacklistFilter = {
        sortBy: {}
    };

    private readonly loadFilteredBlacklist!: (payload: {
        paginationInfo: PaginationInfo;
        blacklistFilter: BlacklistFilter;
    }) => Promise<void>;

    private readonly deleteBlacklist!: (blacklistId: number) => Promise<void>;
    private selectedItemId = 0;

    editItem(blacklistModel: Blacklist): void {
        if (blacklistModel.blacklistId) {
            this.selectedItemId = blacklistModel.blacklistId;
            this.updateDialog = true;
        }
    }

    deleteItem(blacklistModel: Blacklist): void {
        this.selectedItemId = blacklistModel.blacklistId;
        this.deleteDialog = true;
    }

    confirmDeleteBlacklist(): void {
        if (this.selectedItemId) {
            this.deleteBlacklist(this.selectedItemId);
        }
        this.selectedItemId = 0;
    }

    @Watch("options")
    updatedOptions(): void {
        this.getDataFromApi();
    }

    getDataFromApi(): void {
        const { page, itemsPerPage, sortBy, sortDesc } = this.options;

        if (sortBy && sortBy[0] && sortDesc) {
            if (sortBy[0] === "email")
                this.blacklistFilter.sortBy.email = sortDesc[0];
            else this.blacklistFilter.sortBy.email = null;
            if (sortBy[0] === "firstName")
                this.blacklistFilter.sortBy.firstName = sortDesc[0];
            else this.blacklistFilter.sortBy.firstName = null;
            if (sortBy[0] === "lastName")
                this.blacklistFilter.sortBy.lastName = sortDesc[0];
            else this.blacklistFilter.sortBy.lastName = null;
            if (sortBy[0] === "lastModifiedDateTime")
                this.blacklistFilter.sortBy.lastModifiedDateTime = sortDesc[0];
            else this.blacklistFilter.sortBy.lastModifiedDateTime = null;
        } else this.blacklistFilter.sortBy = {};

        if (!page || !itemsPerPage) {
            this.loadFilteredBlacklist({
                paginationInfo: { take: 20, skip: 0 },
                blacklistFilter: this.blacklistFilter
            });
            return;
        }

        this.loadFilteredBlacklist({
            paginationInfo: {
                skip: (page - 1) * itemsPerPage,
                take: itemsPerPage
            },
            blacklistFilter: this.blacklistFilter
        });
    }

    cancel(): void {
        this.selectedItemId = 0;
    }

    getFormattedDateString(dateString: string, showTime?: boolean): string {
        if (!showTime) {
            return new Date(dateString).toLocaleDateString("de-DE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
            });
        } else {
            return new Date(dateString).toLocaleString("de-DE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                second: "2-digit",
                hour: "2-digit",
                minute: "2-digit"
            });
        }
    }
}
