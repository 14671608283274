
import Component from "vue-class-component";
import FieldData from "@/services/fieldData";
import { ContactAddressType } from "@/models/cases/Contact";
import ContactFormBase from "../ContactFromBase/ContactFormBase.vue";
import { PostQualification } from "@/models/postQualification/PostQualification";
import { Ref, Prop } from "vue-property-decorator";
import { VuetifyForm } from "@/models/VuetifyForm";

@Component
export default class ContactPostQualificationForm extends ContactFormBase {
    @Prop({
        type: Object,
        required: true,
        default: {
            hasNameDetails: false,
            hasAddressDetails: false,
            hasCarDetails: false
        }
    })
    protected postQualification!: PostQualification;
    @Prop(Boolean) protected isSubmitting!: boolean;

    @Ref() form!: VuetifyForm;

    isBusinessAddress =
        this.postQualification.addressDetails?.addressType ===
        ContactAddressType.BusinessAddress;

    onAddressTypeInput(value: ContactAddressType | null): void {
        this.isBusinessAddress = value === ContactAddressType.BusinessAddress;
    }

    submit(): void {
        if (this.form.validate()) {
            this.$emit("submit");
        }
    }

    protected readonly salutationData = new FieldData("Salutation", {
        required: true
    });
    protected readonly titleData = new FieldData("Title", {
        maxLength: 10
    });
    protected readonly firstNameData = new FieldData("First name", {
        required: true,
        minLength: 2,
        maxLength: 100
    });
    protected readonly lastNameData = new FieldData("Last name", {
        required: true,
        minLength: 2,
        maxLength: 100
    });
    protected readonly addressTypesData = new FieldData("Address type", {
        required: true
    });
    protected readonly businessNameData = new FieldData("Business name", {
        required: true,
        maxLength: 200
    });
    protected readonly streetData = new FieldData("Street", {
        required: true,
        minLength: 3
    });
    protected readonly houseNumberData = new FieldData("House number", {
        maxLength: 10
    });
    protected readonly zipCodeData = new FieldData("Postal code", {
        required: true,
        minLength: 3,
        maxLength: 15
    });
    protected readonly cityData = new FieldData("City", {
        required: true,
        minLength: 2
    });
    protected readonly countryData = new FieldData("Country", {
        required: true
    });

    protected vin = "";
    protected readonly vinData = new FieldData(
        "Vehicle Identification Number (VIN)",
        {
            required: true,
            length: 17
        }
    );
    protected readonly countryOfRegistrationCode = new FieldData(
        "Country of Registration",
        {
            required: true
        }
    );
}
