import { Case } from "@/models/cases/Case";
import { notificationsModule } from "@/store/modules/notifications";
import { NotificationsActions } from "@/store/modules/notifications/actions";
import { solutionModule } from "@/store/modules/solution";
import { SolutionActions } from "@/store/modules/solution/actions";
import { SolutionGetters } from "@/store/modules/solution/getters";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import backendResponseErrors from "./BackendResponseErrors.json";

export interface SolutionTokenData {
    token: string;
    marketStatus: string;
}

@Component({
    computed: {
        ...solutionModule.mapGetters({
            errors: SolutionGetters.Errors,
            loading: SolutionGetters.Loading,
            caseModel: SolutionGetters.Case
        })
    },
    methods: {
        ...solutionModule.mapActions({
            getSolutionCase: SolutionActions.GetSolutionCase
        }),
        ...notificationsModule.mapActions({
            showError: NotificationsActions.NotifyError
        })
    }
})
export default class BaseSolutionComponent extends Vue {
    protected readonly getSolutionCase!: (
        payload: SolutionTokenData
    ) => Promise<boolean>;

    protected readonly loading!: boolean;
    protected readonly errors!: string | null;
    protected readonly caseModel!: Case | null;

    protected readonly showError!: (
        payload: string | string[]
    ) => Promise<void>;

    protected get tokenData(): SolutionTokenData | null {
        const token = this.$route.query.token as string;
        const marketStatus = this.$route.query.marketStatus as string;

        if (token && marketStatus) {
            return { token, marketStatus };
        }
        return null;
    }

    private displayErrors(): void {
        if (this.errors) {
            let errorMessage: string;
            if (Object.keys(backendResponseErrors).includes(this.errors)) {
                errorMessage =
                    this.getBackendResponseErrors()[this.errors].message;
            } else {
                errorMessage = this.errors;
            }
            this.showError(errorMessage);
        }
    }

    private getBackendResponseErrors() {
        return backendResponseErrors as {
            [K: string]: { message: string };
        };
    }

    @Watch("errors")
    updatedErrors(newVal: string | null): void {
        if (newVal && newVal.length) {
            this.displayErrors();
        }
    }
}
