import { Country } from "@/models/markets/Country";
import { Market } from "@/models/markets/Market";
import { MarketInfo } from "@/models/markets/MarketInfo";
import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export interface MarketState {
    info: MarketInfo | null;
    markets: Market[];
    market: Market | null;
    countries: Country[];
    errors: string[] | null;
    loading: boolean;
    regions: string[];
}

const marketState: MarketState = {
    info: null,
    markets: [],
    market: null,
    countries: [],
    errors: [],
    loading: false,
    regions: []
};

const market: Module<MarketState, RootState> = {
    namespaced: true,
    state: marketState,
    actions: actions,
    getters: getters,
    mutations: mutations
};

export const marketModule = createNamespacedHelpers("market");

export default market;
