import caseInputChannels from "@/consts/caseInputChannels";
import { Case } from "@/models/cases/Case";
import { CaseSolutionType } from "@/models/solution/CaseSolutionType";
import * as caseSources from "@/consts/caseSources";

const C3_R1_CASE_SOLUTION_REGION_NAME = "C3-R1";

class CaseSolutionHelper {
    public getCaseSolitionType(caseModel: Case): CaseSolutionType {
        if (
            caseModel.inputChannel?.name.toUpperCase() ==
            caseInputChannels.SOCIAL_MEDIA.toUpperCase()
        )
            return CaseSolutionType.SocialMedia;

        if (
            caseModel.market?.region.trim().toUpperCase() ==
            C3_R1_CASE_SOLUTION_REGION_NAME
        )
            return CaseSolutionType.C3_R1;

        if (
            caseModel.source?.name?.toUpperCase() ===
            caseSources.BOARD.toUpperCase()
        )
            return CaseSolutionType.Board;

        return CaseSolutionType.Common;
    }

    public isComplexSolution(type: CaseSolutionType): boolean {
        return type !== CaseSolutionType.Common;
    }

    public isComplexSolutionCase(caseModel: Case): boolean {
        return this.isComplexSolution(this.getCaseSolitionType(caseModel));
    }
}

export default new CaseSolutionHelper();
