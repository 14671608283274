import { CaseInfo } from "@/models/cases/CaseInfo";
import { PaginationResult } from "@/models/PaginationResult";
import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import { CaseListItem } from "@/models/cases/CaseListItem";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export interface CasesState {
    info: CaseInfo;
    infoLoadingPromise: Promise<void> | null;
    paginationData: PaginationResult<CaseListItem>;
    favoriteCases: number[];
    isLoading: boolean;
    arePresetsApplied: boolean;
}

const state: CasesState = {
    info: {
        sources: [],
        inputChannels: [],
        statuses: [],
        socialMediaChannels: [],
        socialMediaAgencies: [],
        escalationLevels: [],
        mailboxes: [],
        bmwDepartments: [],
        bmwConcerns: [],
        blacklistEmails: []
    },
    infoLoadingPromise: null,
    paginationData: {
        totalCount: 0,
        items: []
    },
    isLoading: false,
    arePresetsApplied: false,
    favoriteCases: []
};

const moduleState: Module<CasesState, RootState> = {
    namespaced: true,
    state: state,
    actions: actions,
    getters: getters,
    mutations: mutations
};

export const namespace = "cases";
export const module = createNamespacedHelpers(namespace);

export default moduleState;
