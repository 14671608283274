
import Vue from "vue";
import Component from "vue-class-component";
import { Routes } from "@/router/routes";
import { accountModule } from "@/store/modules/account";
import { AccountActions } from "@/store/modules/account/actions";
import { AccountGetters } from "@/store/modules/account/getters";
import { Session } from "@/models/Session";

type MenuItem = {
    name: string;
    href?: string;
    action?: (() => void) | (() => Promise<void>);
};

@Component({
    computed: {
        ...accountModule.mapGetters({
            session: AccountGetters.Session
        })
    },
    methods: {
        ...accountModule.mapActions({
            logUserOut: AccountActions.LogUserOut
        })
    }
})
export default class UserBadge extends Vue {
    private readonly session!: Session | null;
    protected readonly logUserOut!: () => Promise<void>;

    protected readonly infoMenuItems: MenuItem[] = [
        {
            href: "/manuals/IFM_FIRST_STEPS_EN.pdf",
            name: "First Steps EN"
        },
        {
            href: "/manuals/IFM_FIRST_STEPS_DE.pdf",
            name: "First Steps DE"
        },
        {
            href: "/manuals/2023_01_30_IFM_USER_GUIDE_EN.pdf",
            name: "User Manual EN"
        },
        {
            href: "/manuals/2023_01_30_IFM_USER_GUIDE_DE.pdf",
            name: "User Manual DE"
        }
    ];

    protected readonly accountMenuItems: MenuItem[] = [
        {
            name: "Log out",
            action: this.logout
        }
    ];

    protected isAccountMenuVisible = false;
    protected isInfoMenuVisible = false;

    get fullname(): string {
        if (!this.session) return "";

        const { firstName, lastName } = this.session.User;
        return `${firstName} ${lastName}`;
    }

    async logout(): Promise<void> {
        await this.logUserOut();
        this.$router.push(Routes.Login);
    }
}
