
import logo from "@/assets/img/BMWGroup_BMW_MINI_White-Colour_RGB.png";

import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class AuthCard extends Vue {
    readonly logo = logo;
}
