
import Vue from "vue";
import { Component, Prop, Ref } from "vue-property-decorator";
import FieldData from "@/services/fieldData";
import { VuetifyForm } from "@/models/VuetifyForm";

@Component
export default class TwoFactorForm extends Vue {
    @Prop(Boolean)
    readonly loading!: boolean;

    @Ref() readonly form!: VuetifyForm;

    twoFactorPassword = "";

    readonly secuityCodeData = new FieldData("Security code", {
        required: true,
        regex: {
            pattern: /^(\d{6}|[a-z0-9]{8})$/,
            message: "Security code is has to be 6 digits or 8 characters."
        }
    });

    submit(): void {
        if (this.form.validate()) this.$emit("submit", this.twoFactorPassword);
    }
}
