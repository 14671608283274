const EMAIL = "E-Mail";
const TELEPHONE = "Telephone";
const BRIEF = "Brief";
const SOCIAL_MEDIA = "Social Media";

const inputChannels = {
    EMAIL,
    TELEPHONE,
    BRIEF,
    SOCIAL_MEDIA
};

Object.freeze(inputChannels);

export default inputChannels;
