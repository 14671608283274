/* eslint-disable no-unused-vars */
export enum Routes {
    Home = "/",
    Login = "/account/login",
    RequestPasswordReset = "/account/request-password-reset",
    PasswordReset = "/account/password-reset",
    AccountActivation = "/account/activate",
    TwoFactorActivation = "/account/2fa",
    Users = "/users",
    EditUser = "/users/edit/",
    CreateUser = "/users/create",
    Error = "/error/",
    Markets = "/markets",
    MarketCreate = "/market/create",
    MarketEdit = "/market/edit/",
    CaseOverview = "/cases",
    CaseCreate = "/case/create",
    CaseEdit = "/case/edit/",
    GdprSearch = "/gdpr",
    GdprEdit = "/gdpr/contact/",
    CaseBMWOverview = "/cases/bmw",
    Rules = "/rules",
    RuleEdit = "/rule/edit/",
    RuleCreate = "/rule/create",
    PostQualification = "/post-qualification/",
    PostQualificationThanks = "/post-qualification/thanks",
    PostQualificationNotFound = "/post-qualification/not-found",
    DailyReport = "/reports/daily",
    YtdReport = "/reports/ydt",
    BillingAccount = "/billing-account",
    Billing = "/billing",
    Blacklist = "/blacklist",
    SolutionAddMoreDays = "/solution/add-more-days",
    SolutionSolve = "/solution/solve",
    Favorites = "/favorites",
    MonthlyReport = "/monthly-report"
}
