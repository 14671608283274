import { MutationTree } from "vuex";
import { BillingAccountState } from ".";
import { AccountInfo } from "@/models/billingAccount/AccountInfo";

export enum BillingAccountMutation {
    SetAccountInfo = "setAccountInfo",
    SetLoading = "setLoading",
    SetErrors = "setErrors",
    SetYearAndMonth = "setYearAndMonth"
}

const mutations: MutationTree<BillingAccountState> = {
    [BillingAccountMutation.SetAccountInfo](state, payload: AccountInfo) {
        state.accountInfo = payload;
    },
    [BillingAccountMutation.SetLoading](state, payload: boolean) {
        state.isLoading = payload;
    },
    [BillingAccountMutation.SetErrors](state, payload: string[]) {
        state.errors = payload;
    },
    [BillingAccountMutation.SetYearAndMonth](
        state,
        payload: { year: number; month: number }
    ) {
        state.year = payload.year;
        state.month = payload.month;
    }
};

export default mutations;
