
import Vue from "vue";
import Component from "vue-class-component";

import logo from "@/assets/img/BMWGroup_BMW_MINI_Grey-Colour_RGB.png";

@Component({
    data() {
        return {
            logo
        };
    }
})
export default class ExternalLayout extends Vue {}
