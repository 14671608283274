import { RootState } from "@/store";
import { ActionTree } from "vuex";
import { NotificationsState } from ".";
import { NotificationsMutation } from "./mutations";
import { colors } from "vuetify/lib";

export enum NotificationsActions {
    Notify = "notify",
    NotifyError = "notifyError",
    NotifySuccess = "notifySuccess"
}

const actions: ActionTree<NotificationsState, RootState> = {
    [NotificationsActions.Notify](
        { commit },
        { message, color }: { message: string; color: string }
    ) {
        commit(NotificationsMutation.SetShowing, true);
        commit(NotificationsMutation.SetNotificationsMessage, message);
        commit(NotificationsMutation.SetColour, color);
    },
    [NotificationsActions.NotifyError]({ dispatch }, payload: string) {
        dispatch(NotificationsActions.Notify, {
            message: payload,
            color: colors.red.base
        });
    },
    [NotificationsActions.NotifySuccess]({ dispatch }, payload: string) {
        dispatch(NotificationsActions.Notify, {
            message: payload,
            color: colors.green.base
        });
    }
};

export default actions;
