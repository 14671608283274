import { MonthlyReport } from "@/models/reports/MonthlyReport";
import { AxiosResponse } from "axios";
import api from ".";

function getMonthlyReport(): Promise<AxiosResponse<MonthlyReport>> {
    return api.get<MonthlyReport>("/cases/reporting/monthly");
}

function setMonthlyReport(
    monthlyReport: MonthlyReport
): Promise<AxiosResponse<void>> {
    return api.put("/cases/reporting/monthly", monthlyReport);
}

export default {
    getMonthlyReport,
    setMonthlyReport
};
