import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { BillingState } from ".";

export enum BillingGetters {
    MonthBillingData = "monthBillingData",
    IsLoading = "isLoading",
    Errors = "errors",
    Year = "year",
    Month = "month"
}

const getters: GetterTree<BillingState, RootState> = {
    [BillingGetters.MonthBillingData](state) {
        return state.monthBillingData;
    },
    [BillingGetters.IsLoading](state) {
        return state.isLoading;
    },
    [BillingGetters.Errors](state) {
        return state.errors;
    },
    [BillingGetters.Year](state) {
        return state.year;
    },
    [BillingGetters.Month](state) {
        return state.month;
    }
};

export default getters;
