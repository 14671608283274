import { PaginationResult } from "@/models/PaginationResult";
import { Rule } from "@/models/rules/Rule";
import { RuleInfo } from "@/models/rules/RuleInfo";
import { MutationTree } from "vuex";
import { RuleState } from ".";

export enum RuleMutations {
    SetLoading = "setLoading",
    SetErrors = "setErrors",
    SetInfo = "setInfo",
    SetRule = "setRule",
    SetRules = "setRules"
}

const mutations: MutationTree<RuleState> = {
    [RuleMutations.SetErrors](state, payload: string[] | null) {
        state.errors = payload;
    },
    [RuleMutations.SetInfo](state, payload: RuleInfo | null) {
        state.info = payload;
    },
    [RuleMutations.SetLoading](state, payload: boolean) {
        state.isLoading = payload;
    },
    [RuleMutations.SetRule](state, payload: Rule | null) {
        state.rule = payload;
    },
    [RuleMutations.SetRules](state, payload: PaginationResult<Rule>) {
        state.rules = payload;
    }
};

export default mutations;
