
import { ContactAddressType, ContactSalutation } from "@/models/cases/Contact";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import DatePicker from "@/components/DatePicker";
import { getSelectDataSourceFromEnum } from "@/services/dataSourceUtils";
import { marketModule } from "@/store/modules/market";
import { MarketGetters } from "@/store/modules/market/getters";
import { MarketActions } from "@/store/modules/market/actions";
import { Country } from "@/models/markets/Country";

@Component({
    components: { DatePicker },
    computed: {
        ...marketModule.mapGetters({
            countries: MarketGetters.Countries
        })
    },
    methods: {
        ...marketModule.mapActions({
            loadCountries: MarketActions.LoadCountries
        })
    }
})
export default class ContactFormBase extends Vue {
    private readonly loadCountries!: () => Promise<void>;
    protected readonly countries!: Country[];

    protected salutations = getSelectDataSourceFromEnum(ContactSalutation);
    protected addressTypes = getSelectDataSourceFromEnum(ContactAddressType);

    created(): void {
        this.loadCountries();
    }
}
