import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import { AccountInfo } from "@/models/billingAccount/AccountInfo";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export interface BillingAccountState {
    accountInfo: AccountInfo;
    isLoading: boolean;
    errors: string[];
    year: number;
    month: number;
}

const billingAccountState: BillingAccountState = {
    accountInfo: {
        billingSummaries: [],
        isBillingPossible: false
    },
    isLoading: false,
    errors: [],
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1
};

const billingAccountModuleState: Module<BillingAccountState, RootState> = {
    namespaced: true,
    state: billingAccountState,
    actions: actions,
    getters: getters,
    mutations: mutations
};

export const billingAccountModule = createNamespacedHelpers("billingAccount");

export default billingAccountModuleState;
