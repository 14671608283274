import { CodeLevelData } from "@/models/CodeLevelData";
import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { CodeLevelState } from ".";

export enum CodeLevelGetters {
    CodeLevelData = "codeLevelData",
    IsLoading = "isLoading",
    Errors = "errors"
}

const getters: GetterTree<CodeLevelState, RootState> = {
    [CodeLevelGetters.CodeLevelData](state): CodeLevelData | null {
        return state.data;
    },
    [CodeLevelGetters.Errors](state): string[] | null {
        return state.errors;
    },
    [CodeLevelGetters.IsLoading](state): boolean {
        return state.isLoading;
    }
};

export default getters;
