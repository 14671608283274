import { Country } from "@/models/markets/Country";
import { Market } from "@/models/markets/Market";
import { MarketInfo } from "@/models/markets/MarketInfo";
import { MutationTree } from "vuex";
import { MarketState } from ".";

export enum MarketMutation {
    SetMarkets = "setMarkets",
    SetMarket = "setMarket",
    SetInfo = "setInfo",
    SetLoading = "setLoading",
    SetRegions = "setRegions",
    SetErrors = "setErrors",
    RemoveMarket = "removeMarket",
    SetCountries = "setCountries"
}

const mutations: MutationTree<MarketState> = {
    [MarketMutation.SetMarkets](state, payload: Market[]) {
        state.markets = payload;
    },
    [MarketMutation.SetMarket](state, payload: Market | null) {
        state.market = payload;
    },
    [MarketMutation.SetInfo](state, payload: MarketInfo) {
        state.info = payload;
    },
    [MarketMutation.SetLoading](state, payload: boolean) {
        state.loading = payload;
    },
    [MarketMutation.SetRegions](state, payload: string[]) {
        state.regions = payload;
    },
    [MarketMutation.SetErrors](state, payload: string[]) {
        state.errors = payload;
    },
    [MarketMutation.RemoveMarket](state, payload: number) {
        state.markets = state.markets.filter((m) => m.marketId !== payload);
        if (state.market?.marketId == payload) {
            state.market = null;
        }
    },
    [MarketMutation.SetCountries](state, payload: Country[]) {
        state.countries = payload;
    }
};

export default mutations;
