
import Vue from "vue";
import Component from "vue-class-component";
import SideMenuItem from "../SideMenuItem";
import SideMenuTitle from "../SideMenuTitle";
import { Routes } from "@/router/routes";
import {
    isUserInRoles,
    isUserInRoleTypes,
    userHasClaims
} from "@/services/userUtils";
import { BLACK_LIST, GDPR, REPORTS } from "@/consts/customClaims";
import store from "@/store";
import { namespace as casesNamespace } from "@/store/modules/cases";
import { CasesMutation } from "@/store/modules/cases/mutations";

export interface MenuItem {
    title: string;
    icon?: string;
    mdiIcon?: boolean;
    to?: string;
    exact?: boolean;
    allowedRoles?: string[];
    allowedRoleTypes?: string[];
    claims?: string[];
    onClick?: () => Promise<void>;
}

interface MenuCategory extends MenuItem {
    items?: MenuItem[];
}

@Component({
    components: { SideMenuTitle, SideMenuItem }
})
export default class SideMenu extends Vue {
    readonly menuCategories: MenuCategory[] = [
        {
            title: "Dashboard",
            to: Routes.Home
        },
        {
            title: "Cases",
            items: [
                {
                    title: "Overview",
                    to: Routes.CaseOverview,
                    icon: "menu",
                    mdiIcon: true,
                    onClick: this.onOverviewClick
                },
                {
                    title: "Create Case",
                    to: Routes.CaseCreate,
                    icon: "chat-plus-outline",
                    mdiIcon: true,
                    allowedRoleTypes: ["IFM Admin", "BMW Admin", "Agent"]
                },
                {
                    title: "GDPR Search",
                    to: Routes.GdprSearch,
                    icon: "account-search-outline",
                    mdiIcon: true,
                    claims: [GDPR]
                },
                {
                    title: "Favorites",
                    to: Routes.Favorites,
                    icon: "star-outline",
                    mdiIcon: true
                }
            ]
        },
        {
            title: "Configuration",
            items: [
                {
                    title: "Users",
                    icon: "users",
                    to: Routes.Users,
                    allowedRoleTypes: ["IFM Admin", "BMW Admin"]
                },
                {
                    title: "Markets",
                    icon: "markets",
                    to: Routes.Markets,
                    allowedRoleTypes: ["IFM Admin", "BMW Admin"]
                },
                {
                    title: "Rules",
                    icon: "drama-masks",
                    to: Routes.Rules,
                    mdiIcon: true,
                    allowedRoleTypes: ["IFM Admin"]
                },
                {
                    title: "Blacklist",
                    icon: "file-alert-outline",
                    to: Routes.Blacklist,
                    mdiIcon: true,
                    claims: [BLACK_LIST]
                },
                {
                    title: "Monthly report",
                    icon: "calendar-check",
                    to: Routes.MonthlyReport,
                    mdiIcon: true,
                    allowedRoles: ["Admin", "BMW BCC Team"]
                }
            ]
        },
        {
            title: "Reporting",
            items: [
                {
                    title: "Daily Cases",
                    icon: "car",
                    to: Routes.DailyReport,
                    claims: [REPORTS]
                },
                {
                    title: "YTD",
                    icon: "chart_bar",
                    to: Routes.YtdReport,
                    claims: [REPORTS]
                }
            ]
        },
        {
            title: "Billing",
            items: [
                {
                    to: Routes.BillingAccount,
                    exact: true,
                    title: "Account",
                    icon: "card-account-details-outline",
                    mdiIcon: true,
                    allowedRoleTypes: ["IFM Admin"]
                },
                {
                    to: Routes.Billing,
                    exact: true,
                    title: "Billing report",
                    icon: "newspaper-variant-outline",
                    mdiIcon: true,
                    allowedRoleTypes: ["IFM Admin"]
                }
            ]
        }
    ];

    isMenuItemVisible(
        roles?: string[],
        roleTypes?: string[],
        claims?: string[]
    ): boolean {
        if (roles && !isUserInRoles(roles)) {
            return false;
        }
        if (roleTypes && !isUserInRoleTypes(roleTypes)) {
            return false;
        }
        if (claims && !userHasClaims(claims)) {
            return false;
        }
        return true;
    }

    isMenuCategoryVisible(category: MenuCategory): boolean {
        return (
            category.items?.some((i) =>
                this.isMenuItemVisible(
                    i.allowedRoles,
                    i.allowedRoleTypes,
                    i.claims
                )
            ) ?? false
        );
    }

    async onOverviewClick(): Promise<void> {
        await store.commit(
            `${casesNamespace}/${CasesMutation.SetArePresetsApplied}`,
            false
        );
    }
}
