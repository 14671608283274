import { createNamespacedHelpers, Module } from "vuex";
import { RootState } from "@/store";
import mutations from "./mutations";
import { Route } from "vue-router";
import getters from "./getters";

export interface RouteState {
    previousRoute?: Route;
}

const state: RouteState = {
    previousRoute: undefined
};

const route: Module<RouteState, RootState> = {
    namespaced: true,
    state,
    mutations,
    getters
};

export const routeStoreNamespace = "route";
export const routeHelpers = createNamespacedHelpers("route");

export default route;
