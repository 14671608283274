export const USER_MANAGE = "BMW.InternationalFeedbackManagement.UserManage";
export const ROLE_TYPE = "BMW.InternationalFeedbackManagement.RoleType";
export const BLACK_LIST = "BMW.InternationalFeedbackManagement.Blacklist";
export const REPORTS = "BMW.InternationalFeedbackManagement.Reports";
export const DELETE_PERMISSION =
    "BMW.InternationalFeedbackManagement.DeletePermission";
export const STOP_REMINDER_PERMISSION =
    "BMW.InternationalFeedbackManagement.StopReminderPermission";
export const BMW_CASES = "BMW.InternationalFeedbackManagement.BMWCases";
export const MARKETS = "BMW.InternationalFeedbackManagement.Markets";
export const EXCLUDE_MARKET =
    "BMW.InternationalFeedbackManagement.ExcludeMarket";
export const GDPR = "BMW.InternationalFeedbackManagement.DSVGO";
export const GDPR_EDITING = "BMW.InternationalFeedbackManagement.DSVGOEditing";
export const CASE_SOURCE = "BMW.InternationalFeedbackManagement.CaseSource";
export const BRAND = "BMW.InternationalFeedbackManagement.Brand";
export const CODE_LEVEL_2 = "BMW.InternationalFeedbackManagement.CodeLevel2";
export const CREATE_POSTQUALIFICATION_PERMISSION =
    "BMW.InternationalFeedbackManagement.CreatePostQualificaionPermission";
export const DELETE_ATTACHMENT_PERMISSION =
    "BMW.InternationalFeedbackManagement.DeleteAttachmentPermission";
export const EDITABLE_CODE_LEVEL =
    "BMW.InternationalFeedbackManagement.EditableCodeLvl";
