import { ContactCar } from "./ContactCar";

export enum ContactSalutation {
    Mr = "Mr",
    Mrs = "Mrs"
}

export enum ContactAddressType {
    HomeAddress = "HomeAddress",
    BusinessAddress = "BusinessAddress"
}

export interface Contact {
    contactId: number;
    salutation?: ContactSalutation;
    title?: string;
    firstName: string;
    lastName: string;
    buName?: string;
    addressType?: ContactAddressType | null;
    street?: string;
    houseNumber?: string;
    addressAdditional1?: string;
    addressAdditional2?: string;
    zipCode?: string;
    city?: string;
    telephonePrivate?: string;
    telephoneB?: string;
    mobilePrivate?: string;
    mobileB?: string;
    fax?: string;
    email1?: string;
    email2?: string;
    email3?: string;
    email4?: string;
    email5?: string;
    socialMedia?: string;
    webSite?: string;
    countryCode?: string;
    cars?: ContactCar[];
}
