import { CaseSolution } from "@/models/solution/CaseSolution";

class SolutionValidationService {
    public requiredFieldsForCaseCost = [
        "warrantyExt",
        "connectedDriveUpdate",
        "vehicleTreatment",
        "experienceVoucher",
        "lifestyleVoucher",
        "buyBack",
        "repairCostCovered",
        "partsCostCovered"
    ];

    public requiredFieldsForDiscountGranted = [
        "repairCostCovered",
        "partsCostCovered",
        "discountOnNextCar"
    ];

    private serviceFields = ["caseId", "caseCodeLevelSetId"];

    public validate(solution: CaseSolution): boolean {
        const solutionDictionary = solution as unknown as {
            [K: string]: number | boolean | string | null;
        };

        let totalFieldsActiveFields = 0;
        Object.keys(solutionDictionary).forEach((key) => {
            if (!this.serviceFields.includes(key) && solutionDictionary[key])
                totalFieldsActiveFields++;
        });
        if (!totalFieldsActiveFields) return false;

        let result = true;
        this.requiredFieldsForCaseCost.forEach((key) => {
            if (solutionDictionary[key] && !solution.caseCost) result = false;
        });
        if (!result) return false;

        this.requiredFieldsForDiscountGranted.forEach((key) => {
            if (solutionDictionary[key] && !solution.discountGranted)
                result = false;
        });

        return result;
    }
}

export default new SolutionValidationService();
