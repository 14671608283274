import { Case } from "@/models/cases/Case";
import { Rule } from "@/models/rules/Rule";
import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export interface CaseState {
    case: Case;
    rules: Rule[];
    loading: boolean;
    replyTemplate: string;
}

const caseState: CaseState = {
    loading: false,
    case: {
        caseId: 0,
        internalId: 0,
        externalId: null,
        parentId: null,
        parent: null,
        title: null,
        mailbox: null,
        source: null,
        sourceId: null,
        inputChannelId: null,
        inputChannel: null,
        statusId: null,
        status: null,
        escalationLevel: null,
        escalationLevelId: null,
        socialMediaAgency: null,
        socialMediaAgencyId: null,
        socialMediaChannel: null,
        socialMediaChannelId: null,
        contact: null,
        contactId: null,
        market: null,
        marketId: null,
        receiptDateTime: null,
        firstEditDateTime: null,
        caseBook: false,
        qualified: false,
        territoryId: null,
        tsaraNumber: null,
        createdDateTime: new Date().toString(),
        lastModifiedDateTime: new Date().toString(),
        technicalCampaignId: null,
        lockedByUser: null,
        isReminderProcessActive: false,
        boardCaseData: null,
        codeLevelSets: [],
        children: [],
        caseEvents: [],
        hasSalesforceCaseId: false
    },
    rules: [],
    replyTemplate: ""
};

const caseModuleState: Module<CaseState, RootState> = {
    namespaced: true,
    state: caseState,
    actions: actions,
    getters: getters,
    mutations: mutations
};

export const caseModule = createNamespacedHelpers("case");

export default caseModuleState;
