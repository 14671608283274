
import Component from "vue-class-component";
import BaseSolutionComponent from "../BaseSolutionComponent";
import SimpleCaseSolution from "@/components/SimpleCaseSolution";
import ComplexCaseSolution from "@/components/ComplexCaseSolution";
import { solutionModule } from "@/store/modules/solution";
import { SolutionActions } from "@/store/modules/solution/actions";
import { SimpleSolveDTO } from "@/models/solution/SimpleSolveDTO";
import { ComplexSolveDTO } from "@/models/solution/ComplexSolveDTO";
import caseSolutionHelper from "@/services/caseSolutionHelper";

@Component({
    components: {
        SimpleCaseSolution,
        ComplexCaseSolution
    },
    methods: {
        ...solutionModule.mapActions({
            simpleSolve: SolutionActions.SimpleSolve,
            complexSolve: SolutionActions.ComplexSolve
        })
    }
})
export default class Solve extends BaseSolutionComponent {
    private readonly simpleSolve!: (payload: SimpleSolveDTO) => Promise<void>;
    private readonly complexSolve!: (payload: ComplexSolveDTO) => Promise<void>;

    protected isSuccessful = false;

    created(): void {
        if (this.tokenData) {
            this.getSolutionCase(this.tokenData);
        } else {
            this.showError("Invalid Token");
        }
    }

    get isComplexSolution(): boolean {
        return (
            this.caseModel !== null &&
            caseSolutionHelper.isComplexSolutionCase(this.caseModel)
        );
    }

    async simpleSolveHandler(solution: string): Promise<void> {
        if (this.tokenData?.token) {
            await this.simpleSolve({ token: this.tokenData.token, solution });
            this.isSuccessful = true;
        }
    }

    async solveComplexHandler(solveData: ComplexSolveDTO): Promise<void> {
        if (this.tokenData?.token) {
            solveData.token = this.tokenData.token;
            await this.complexSolve(solveData);
            this.isSuccessful = true;
        }
    }
}
