
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, ModelSync, Prop } from "vue-property-decorator";
import AppIcon from "@/components/AppIcon";

@Component({
    components: { AppIcon }
})
export default class BaseDialog extends Vue {
    @ModelSync("value", "input", { type: Boolean, required: true })
    valueSync!: boolean;
    @Prop({ type: String, required: true })
    readonly label!: string;
    @Prop({ type: Number, default: 500 })
    readonly width!: number;

    @Emit()
    close(): void {
        this.valueSync = false;
    }
}
