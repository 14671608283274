import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { RouteState } from ".";

export enum RouteGetters {
    PreviousRoute = "previousRoute"
}

const getters: GetterTree<RouteState, RootState> = {
    [RouteGetters.PreviousRoute](state) {
        return state.previousRoute;
    }
};

export default getters;
