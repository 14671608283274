import { MutationTree } from "vuex";
import { BillingState } from ".";
import { MonthBillingData } from "@/models/billing/MonthBillingData";

export enum BillingMutation {
    SetMonthBillingData = "setMonthBillingData",
    SetLoading = "setLoading",
    SetErrors = "setErrors",
    SetYearAndMonth = "setYearAndMonth"
}

const mutations: MutationTree<BillingState> = {
    [BillingMutation.SetMonthBillingData](state, payload: MonthBillingData[]) {
        state.monthBillingData = payload;
    },
    [BillingMutation.SetLoading](state, payload: boolean) {
        state.isLoading = payload;
    },
    [BillingMutation.SetErrors](state, payload: string[]) {
        state.errors = payload;
    },
    [BillingMutation.SetYearAndMonth](
        state,
        payload: { year: number; month: number }
    ) {
        state.year = payload.year;
        state.month = payload.month;
    }
};

export default mutations;
