
import { Component, Prop, PropSync } from "vue-property-decorator";
import Vue from "vue";
import { VueEditor } from "vue2-quill-editor";

@Component({
    components: { VueEditor }
})
export default class HtmlEditor extends Vue {
    @PropSync("content", { type: String, required: true })
    public editorContent!: string;

    // The identifier is needed to be able to use multiple VueEditors at the same time without mixing content
    @Prop({ type: String, required: false, default: "" })
    public identifier!: string;
}
