
import Vue from "vue";
import { Component, Prop, PropSync } from "vue-property-decorator";

@Component
export default class NotificationDialog extends Vue {
    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    @Prop({ type: String, default: "Notification" })
    public title!: string;

    @Prop({ type: String, required: true })
    public message!: string;

    @Prop({ type: String, default: "Ok" })
    public button!: string;

    @Prop({ type: Number, required: false, default: 150 })
    public height!: number;
}
