import { LookupEntity } from "@/models/LookupEntity";
import { stringEnumToArray } from "./enumUtils";
import { sortBy, startCase } from "lodash";
import { LookupEntityWithOrder } from "../models/LookupEntityWithOrder";

export const defaultNullPlaceholder = "— — —";

export function getSelectDataSourceFromEnum(
    value: Record<string, string>,
    addNullValue = true,
    nullPlaceholder = defaultNullPlaceholder
): LookupEntity<string | null>[] {
    const enumValues = stringEnumToArray(value).map((v) => ({
        id: v,
        name: startCase(v)
    }));
    return getSelectDataSource(enumValues, addNullValue, nullPlaceholder);
}

export function getSelectDataSourceFromStrings(
    values: string[],
    addNullValue = true,
    nullPlaceholder = defaultNullPlaceholder
): LookupEntity<string | null>[] {
    const lookupEntities = values.map((v) => ({ id: v, name: v }));
    return getSelectDataSource(lookupEntities, addNullValue, nullPlaceholder);
}

export function getSelectDataSource<T>(
    values: LookupEntity<T>[],
    addNullValue = true,
    nullPlaceholder = defaultNullPlaceholder
): LookupEntity<T | null>[] {
    const result: LookupEntity<T | null>[] = [];
    if (addNullValue) result.push({ id: null, name: nullPlaceholder });
    result.push(...values);
    return result;
}

export function getSortedSelectDataSource<T>(
    values: LookupEntityWithOrder<T | null>[],
    addNullValue = true,
    nullPlaceholder = defaultNullPlaceholder
): LookupEntity<T | null>[] {
    const sortedValues = sortBy(values, (v) => v.sortOrder);
    return getSelectDataSource(sortedValues, addNullValue, nullPlaceholder);
}
