import { CodeLevelData } from "@/models/CodeLevelData";
import { AxiosResponse } from "axios";
import api from ".";

function getCodeLevelData(): Promise<AxiosResponse<CodeLevelData>> {
    return api.get<CodeLevelData>("code-levels");
}

export default {
    getCodeLevelData
};
