
import { Component } from "vue-property-decorator";
import Vue from "vue";
import DynamicTitle from "@/components/DynamicTitle";
import SideFilter from "@/components/SideFilter";
import { DataTableHeader } from "vuetify";
import reportApi from "@/api/reportApi";
import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { DailyReportType } from "@/models/reports/DailyReportType";
import logo from "@/assets/img/BMWGroup_BMW_MINI_Grey-Colour_RGB.png";
import phoneImg from "@/assets/img/phone.png";
import emailImg from "@/assets/img/email.png";
import downloadArrow from "@/assets/img/tray-arrow-down.png";
import { Routes } from "@/router/routes";
import "@/assets/fonts/jsPDF/";

@Component({
    components: { DynamicTitle, SideFilter }
})
export default class DailyReport extends Vue {
    public dateValue: string | Date = new Date();
    logo: string = logo;
    phoneImg: string = phoneImg;
    emailImg: string = emailImg;
    downloadArrow: string = downloadArrow;

    protected headers: DataTableHeader[] = [
        { text: "Cases", value: "internalId" },
        { text: "Customer Name", value: "authorOfConcern" },
        { text: "Repeat concern", value: "repeatConcern", width: 120 },
        { text: "Recipient", value: "recipient", width: 120 },
        { text: "Market", value: "market", width: 100 },
        { text: "Brand", value: "brand", width: 120 },
        { text: "Series/Model", value: "model", width: 130 },
        { text: "Model Code", value: "modelCode", width: 130 },
        { text: "Theme", value: "theme", width: 120 },
        { text: "Reason German", value: "keyIssueGerman", width: 110 },
        { text: "Reason English", value: "keyIssueInternational", width: 110 }
    ];
    // this is to make the pdf look nicer, removing the last column as it is not necessary as we are limited in time
    protected get headersForPdf(): RowInput[] {
        return [
            {
                ...this.headers
                    .filter((h) => h.text !== "Reason English")
                    .flatMap((item) =>
                        item.text.toUpperCase().split(" ").join("\n")
                    )
            }
        ];
    }
    protected items: DailyReportType[] = [];

    get value(): string {
        if (this.dateValue) {
            let strValue: string;
            if (this.dateValue instanceof Date) {
                strValue = this.dateValue.toISOString();
            } else {
                strValue = this.dateValue;
            }
            if (this.dateValue) {
                return strValue.substring(0, 10);
            }
        }
        return "";
    }

    set value(value: string) {
        this.dateValue = value;
    }

    get caseEditRoute(): Routes {
        return Routes.CaseEdit;
    }

    created(): void {
        this.loadReport();
    }

    loadReport(): void {
        reportApi.getDailyReport(this.dateValue).then((r) => {
            if (r.data) {
                this.items = r.data;
            }
        });
    }

    applyFilter(): void {
        this.loadReport();
    }

    resetToDefault(): void {
        this.dateValue = new Date();
        this.loadReport();
    }

    downloadPdfReport(): void {
        const doc = new jsPDF();
        doc.addImage(this.logo, "PNG", 15, 10, 90, 14);
        let marginX = 65;
        let cursorY = 40;

        doc.setFont("BMWTypeNextLatinTT-Bold", "normal");
        doc.setFontSize(20);
        doc.text(`Daily report ${this.value}`, marginX, cursorY);
        cursorY += 10;

        const minRowHeight = 15;

        autoTable(doc, {
            startY: cursorY,
            willDrawCell: (data) => {
                if (data.column.index === 0 && data.row.section !== "head") {
                    const currentIndex = data.row.index;
                    const caseId = this.items[currentIndex].caseId;
                    doc.addImage(
                        this.downloadArrow,
                        "PNG",
                        data.cell.x + data.cell.width / 2 - 3,
                        data.cell.y + data.cell.height / 2 - 7,
                        6,
                        6
                    );
                    doc.link(
                        data.cell.x + data.cell.width / 2 - 3,
                        data.cell.y + data.cell.height / 2 - 6,
                        6,
                        5,
                        { url: `${window.location.origin}/case/edit/${caseId}` }
                    );
                }
            },
            didDrawPage: (data) => {
                if (data.cursor?.y) {
                    marginX = 15;
                    cursorY = data.cursor.y + 15;
                } else {
                    throw new Error();
                }
            },
            head: this.headersForPdf,
            body: this.items.map((item) => [
                item.internalId.toString(),
                item.authorOfConcern?.split(" ").join("\n"),
                item.repeatConcern,
                item.recipient,
                item.market?.split(" ").join("\n"),
                item.brand?.split(" ").join("\n"),
                item.model?.split(" ").join("\n"),
                item.modelCode?.split(" ").join("\n"),
                item.theme?.split(" ").join("\n"),
                item.keyIssueGerman?.split(" ").join("\n")
            ]),
            theme: "plain",
            styles: {
                lineWidth: 0.3,
                lineColor: [220, 220, 220],
                valign: "middle",
                halign: "center",
                cellWidth: "auto",
                overflow: "linebreak",
                fontSize: 8,
                minCellHeight: minRowHeight,
                cellPadding: 1
            },
            headStyles: {
                font: "BMWTypeNextLatinTT-Bold"
            },
            bodyStyles: {
                font: "BMWTypeNextLatinTT-Regular"
            }
        });

        doc.setFont("BMWTypeNextLatinTT-Regular", "normal");
        doc.setFontSize(10);
        doc.text(
            "If you have any questions, please contact:",
            marginX,
            cursorY
        );

        cursorY += 15;

        doc.setFont("BMWTypeNextLatinTT-Bold", "normal");
        doc.setFontSize(16);
        doc.text("Complaint Management CA-12", marginX, cursorY);

        cursorY += 12;

        doc.addImage(this.phoneImg, "PNG", marginX, cursorY - 5, 6, 6);
        doc.setFont("BMWTypeNextLatinTT-Regular", "normal");
        doc.setFontSize(12);

        doc.addImage(this.emailImg, "PNG", marginX, cursorY - 5, 6, 6);
        doc.text("board.customercare@bmwgroup.com", marginX + 6, cursorY);
        doc.save(`Daily report ${this.value}.pdf`);
    }
}
