import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import { Routes } from "./routes";
import Login from "@/pages/Login";
import PasswordReset from "@/pages/PasswordReset";
import RequestPasswordReset from "@/pages/RequestPasswordReset";
import AccountActivation from "@/pages/AccountActivation";
import TwoFactorActivation from "@/pages/TwoFactorActivation";
import PostQualificationThanks from "@/pages/PostQualificationThanks";
import PostQualificationNotFound from "@/pages/PostQualificationNotFound";
import ContactPostQualification from "@/pages/ContactPostQualification";
import DailyReport from "@/pages/Report/Daily";
import BlacklistOverview from "@/pages/Blacklist";
import SolutionAddMoreDays from "@/pages/Solution/AddMoreDays";
import SolutionSolve from "@/pages/Solution/Solve";
import YtdReport from "@/pages/YtdReport";
import MonthlyReportPage from "@/pages/MonthlyReport";
import { BLACK_LIST, GDPR, REPORTS } from "@/consts/customClaims";
import { setupRouterHooks } from "@/services/routerSetupUtils";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: Routes.Home,
        name: "home",
        redirect: Routes.CaseOverview,
        meta: {
            title: "Home",
            allowAnonymous: false
        }
    },
    {
        path: Routes.Login,
        name: "login",
        component: Login,
        meta: {
            layout: "authLayout",
            title: "Login",
            allowAnonymous: true
        }
    },
    {
        path: Routes.RequestPasswordReset,
        name: "requestPasswordReset",
        component: RequestPasswordReset,
        meta: {
            layout: "authLayout",
            title: "Reset Password",
            allowAnonymous: true
        }
    },
    {
        path: Routes.PasswordReset,
        name: "passwordReset",
        component: PasswordReset,
        meta: {
            layout: "authLayout",
            title: "Reset Password",
            allowAnonymous: true
        }
    },
    {
        path: Routes.AccountActivation,
        name: "accountActivation",
        component: AccountActivation,
        meta: {
            layout: "authLayout",
            title: "Activate account",
            allowAnonymous: true
        }
    },
    {
        path: Routes.TwoFactorActivation,
        name: "twoFactorActivation",
        component: TwoFactorActivation,
        meta: {
            layout: "authLayout",
            title: "Activate two factor authentication",
            allowAnonymous: true
        }
    },
    {
        path: Routes.Users,
        name: "users",
        component: () =>
            import(/* webpackChunkName: "users" */ "@/pages/Users"),
        meta: {
            title: "Users",
            allowedRoleTypes: ["IFM Admin", "BMW Admin"]
        }
    },
    {
        path: Routes.EditUser + ":id",
        name: "editUser",
        component: () =>
            import(/* webpackChunkName: "users" */ "@/pages/EditUser"),
        meta: {
            title: "Edit User",
            allowedRoleTypes: ["IFM Admin", "BMW Admin"]
        }
    },
    {
        path: Routes.CreateUser,
        name: "createUser",
        component: () =>
            import(/* webpackChunkName: "users" */ "@/pages/CreateUser"),
        meta: {
            title: "Create User",
            allowedRoleTypes: ["IFM Admin", "BMW Admin"]
        }
    },
    {
        path: Routes.Markets,
        name: "markets",
        component: () =>
            import(/* webpackChunkName: "markets" */ "@/pages/Markets/List"),
        meta: {
            title: "Markets",
            allowAnonymous: false,
            allowedRoleTypes: ["IFM Admin", "BMW Admin"]
        }
    },
    {
        path: Routes.MarketCreate,
        name: "marketsCreate",
        component: () =>
            import(/* webpackChunkName: "markets" */ "@/pages/Markets/Create"),
        meta: {
            title: "Create Market",
            allowedRoleTypes: ["IFM Admin", "BMW Admin"]
        }
    },
    {
        path: Routes.MarketEdit + ":id",
        name: "marketsEdit",
        component: () =>
            import(/* webpackChunkName: "markets" */ "@/pages/Markets/Edit"),
        meta: {
            title: "Edit Market",
            allowedRoleTypes: ["IFM Admin", "BMW Admin"]
        }
    },
    {
        path: Routes.CaseOverview,
        name: "caseOverview",
        component: () =>
            import(/* webpackChunkName: "cases" */ "@/pages/Case/Overview"),
        meta: {
            title: "Case Overview",
            layout: "withFilterLayout"
        }
    },
    {
        path: Routes.Favorites,
        name: "favorites",
        component: () =>
            import(/* webpackChunkName: "cases" */ "@/pages/Case/Favorites"),
        meta: {
            title: "Favorites",
            allowAnonymous: false
        }
    },
    {
        path: Routes.CaseCreate,
        name: "caseCreate",
        component: () =>
            import(/* webpackChunkName: "cases" */ "@/pages/Case/Create"),
        meta: {
            title: "Create Case",
            allowedRoleTypes: ["IFM Admin", "BMW Admin", "Agent"]
        }
    },
    {
        path: Routes.CaseEdit + ":id",
        name: "caseEdit",
        component: () =>
            import(/* webpackChunkName: "cases" */ "@/pages/Case/Edit"),
        meta: {
            title: "Case Edit"
        }
    },
    {
        path: Routes.GdprSearch,
        name: "gdprSearch",
        component: () => import("@/pages/Gdpr/List"),
        meta: {
            layout: "withFilterLayout",
            title: "GDPR Search",
            claims: [GDPR]
        }
    },
    {
        path: Routes.GdprEdit + ":id",
        name: "gdprEdit",
        component: () => import("@/pages/Gdpr/Edit"),
        meta: {
            title: "GDPR Edit",
            claims: [GDPR]
        }
    },
    {
        path: Routes.Rules,
        name: "rules",
        component: () =>
            import(/* webpackChunkName: "rules" */ "@/pages/Rule/List"),
        meta: {
            title: "Rules",
            allowedRoleTypes: ["IFM Admin"]
        }
    },
    {
        path: Routes.RuleCreate,
        name: "ruleCreate",
        component: () =>
            import(/* webpackChunkName: "rules" */ "@/pages/Rule/Create"),
        meta: {
            title: "Create new Rule",
            allowedRoleTypes: ["IFM Admin"]
        }
    },
    {
        path: Routes.RuleEdit + ":id",
        name: "rulesEdit",
        component: () =>
            import(/* webpackChunkName: "rules" */ "@/pages/Rule/Edit"),
        meta: {
            title: "Rule Edit",
            allowedRoles: ["Admin"]
        }
    },
    {
        path: Routes.BillingAccount,
        name: "billingAccount",
        component: () =>
            import(/* webpackChunkName: "billing" */ "@/pages/BillingAccount"),
        meta: {
            title: "Account",
            layout: "withFilterLayout",
            allowedRoleTypes: ["IFM Admin"]
        }
    },
    {
        path: Routes.Billing,
        name: "billing",
        component: () =>
            import(/* webpackChunkName: "billing" */ "@/pages/Billing"),
        meta: {
            title: "Billing",
            layout: "withFilterLayout",
            allowedRoleTypes: ["IFM Admin"]
        }
    },
    {
        path: Routes.PostQualificationThanks,
        name: "postQualificationThanks",
        component: PostQualificationThanks,
        meta: {
            layout: "externalLayout",
            title: "Post Qualification Thanks",
            allowAnonymous: true
        }
    },
    {
        path: Routes.PostQualificationNotFound,
        name: "postQualificationNotFound",
        component: PostQualificationNotFound,
        meta: {
            layout: "externalLayout",
            title: "Post Qualification Not Found",
            allowAnonymous: true
        }
    },
    {
        path: Routes.PostQualification + ":token*",
        name: "postQualification",
        component: ContactPostQualification,
        meta: {
            layout: "externalLayout",
            title: "Post Qualification",
            allowAnonymous: true
        }
    },
    {
        path: Routes.DailyReport,
        name: "dailyReport",
        component: DailyReport,
        meta: {
            layout: "withFilterLayout",
            title: "Daily report",
            allowAnonymous: false,
            claims: [REPORTS]
        }
    },
    {
        path: Routes.YtdReport,
        name: "ytdReport",
        component: YtdReport,
        meta: {
            title: "YTD Report",
            allowAnonymous: false
        }
    },
    {
        path: Routes.Blacklist,
        name: "blackList",
        component: BlacklistOverview,
        meta: {
            title: "Blacklist",
            allowAnonymous: false,
            claims: [BLACK_LIST]
        }
    },
    {
        path: Routes.MonthlyReport,
        name: "monthlyReport",
        component: MonthlyReportPage,
        meta: {
            title: "MonthlyReport",
            allowAnonymous: false,
            allowedRoles: ["Admin", "BMW BCC Team"]
        }
    },
    {
        path: Routes.SolutionAddMoreDays,
        name: "solutionAddMoreDays",
        component: SolutionAddMoreDays,
        meta: {
            layout: "externalLayout",
            title: "Solution Add More Days",
            allowAnonymous: true
        }
    },
    {
        path: Routes.SolutionSolve,
        name: "solutionSolve",
        component: SolutionSolve,
        meta: {
            layout: "externalLayout",
            title: "Solve Case",
            allowAnonymous: true
        }
    },
    {
        path: Routes.Error + ":code",
        name: "error",
        component: () => import("@/pages/Error"),
        meta: {
            title: "Error"
        }
    },
    {
        path: "*",
        name: "404",
        redirect: () => Routes.Error + "404"
    }
];

if (process.env.NODE_ENV === "development") {
    routes.push({
        path: "/test/create-post-qualification",
        name: "testPostQualificationCreateDialog",
        component: () =>
            import(
                /* webpackChunkName: "testPostQualificationCreateDialog" */ "@/components/PostQualificationCreateDialog"
            ),
        props: {
            isActive: true,
            contactId: 1
        },
        meta: {
            title: "Test",
            allowAnonymous: true
        }
    });
    routes.push({
        path: "/test/auth",
        name: "testPostQualificationCreateDialog",
        component: () => import("@/components/AuthCard"),
        meta: {
            layout: "authLayout",
            title: "Test auth",
            allowAnonymous: true
        }
    });
    routes.push({
        path: "/test/contact-reference",
        name: "testContactReference",
        component: () =>
            import(
                /* webpackChunkName: "testContactReference" */ "@/components/ContactReference"
            ),
        props: {},
        meta: {
            title: "Test",
            allowAnonymous: true
        }
    });
}

const router = new VueRouter({
    routes: routes,
    mode: "history"
});

setupRouterHooks(router);

export default router;
