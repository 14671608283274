import { Case } from "@/models/cases/Case";
import { MutationTree } from "vuex";
import { SolutionState } from ".";

export enum SolutionMutations {
    SetErrors = "setErrors",
    SetLoading = "setLoading",
    SetCase = "setCase"
}

const mutations: MutationTree<SolutionState> = {
    [SolutionMutations.SetErrors](
        state,
        payload: string[] | string | null
    ): void {
        state.errors = payload;
    },
    [SolutionMutations.SetLoading](state, payload: boolean): void {
        state.loading = payload;
    },
    [SolutionMutations.SetCase](state, payload: Case | null): void {
        state.case = payload;
    }
};

export default mutations;
