
import Vue from "vue";
import Component from "vue-class-component";

const AppIconProps = Vue.extend({
    props: {
        mdi: {
            type: Boolean,
            required: false,
            default: false
        },
        color: {
            type: String,
            required: false,
            default: ""
        },
        icon: {
            type: String,
            required: true
        },
        left: {
            type: Boolean,
            required: false,
            default: false
        },
        right: {
            type: Boolean,
            required: false,
            default: false
        },
        large: {
            type: Boolean,
            required: false,
            default: false
        },
        medium: {
            type: Boolean,
            required: false,
            default: false
        }
    }
});

@Component
export default class AppIcon extends AppIconProps {
    get computedClasses(): string[] {
        const classes = ["icon"];

        if (this.mdi) {
            classes.push("mdi");
            classes.push(`mdi-${this.icon}`);
        } else {
            classes.push("app-icon");
            classes.push(`app-icon-${this.icon}`);
        }

        if (this.left) classes.push("left");
        if (this.right) classes.push("right");
        if (this.large) classes.push("large");
        if (this.medium) classes.push("medium");

        return classes;
    }
    get styles(): unknown | null {
        if (this.color) {
            if (this.mdi) {
                return { color: this.color };
            }
            return {
                backgroundColor: this.color
            };
        }
        return null;
    }
}
