import { Contact } from "@/models/cases/Contact";
import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { PaginationResult } from "@/models/PaginationResult";
import { ContactListDto } from "@/models/Gdpr/ContactListDto";

export interface ContactState {
    contacts: Contact[];
    contact: Contact | null;
    errors: string[] | null;
    loading: boolean;
    paginationData: PaginationResult<ContactListDto>;
}

const contactState: ContactState = {
    contacts: [],
    contact: null,
    errors: [],
    loading: false,
    paginationData: {
        totalCount: 0,
        items: []
    }
};

const contact: Module<ContactState, RootState> = {
    namespaced: true,
    state: contactState,
    actions: actions,
    getters: getters,
    mutations: mutations
};

export const contactModule = createNamespacedHelpers("contact");

export default contact;
