
import { CaseSolution } from "@/models/solution/CaseSolution";
import validationRules from "@/services/validationRules";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import solutionValidationService from "@/services/solutionValidationService";
import { NUMBER_ONLY_REGEX } from "@/consts/validationRegex";

@Component
export default class CaseSolutionFields extends Vue {
    @Prop({ type: Object, required: true })
    public readonly solution!: CaseSolution;

    protected caseCost = false;
    protected caseCostDisabled = false;

    protected discountGranted = false;
    protected discountGrantedDisabled = false;

    protected detailedConsalting = false;

    protected caseCostFieldValidationRules = [
        validationRules.required("Case cost"),
        validationRules.regex(NUMBER_ONLY_REGEX, "Only numbers are allowed")
    ];
    protected discountFieldValidationRules = [
        validationRules.required("Discount"),
        validationRules.regex(NUMBER_ONLY_REGEX, "Only numbers are allowed"),
        validationRules.max(100, "Discount")
    ];
    protected textFieldValidationRules = [
        validationRules.required("Detailed consulting")
    ];

    get caseCostValue(): string | null {
        return this.solution.caseCost?.toString() ?? null;
    }

    set caseCostValue(value: string | null) {
        if (value && NUMBER_ONLY_REGEX.test(value)) {
            this.solution.caseCost = Number(value);
        }
    }

    get discountGrantedValue(): string | null {
        return this.solution.discountGranted?.toString() ?? null;
    }

    set discountGrantedValue(value: string | null) {
        if (value && NUMBER_ONLY_REGEX.test(value)) {
            this.solution.discountGranted = Number(value);
        }
    }

    @Watch("caseCost")
    caseCostChanged(value: boolean): void {
        if (!value) {
            this.solution.caseCost = null;
        }
    }

    @Watch("discountGranted")
    discountGrantedChanged(value: boolean): void {
        if (!value) {
            this.solution.discountGranted = null;
        }
    }

    @Watch("detailedConsalting")
    detailedConsaltingChanged(value: boolean): void {
        if (!value) {
            this.solution.detailedConsalting = null;
        }
    }

    solutionUpdated(): void {
        this.caseCostDisabled = false;
        this.discountGrantedDisabled = false;

        const solutionDictionary = this.solution as unknown as {
            [K: string]: number | boolean | string | null;
        };

        solutionValidationService.requiredFieldsForCaseCost.forEach((field) => {
            if (solutionDictionary[field]) {
                this.caseCost = true;
                this.caseCostDisabled = true;
            }
        });

        solutionValidationService.requiredFieldsForDiscountGranted.forEach(
            (field) => {
                if (solutionDictionary[field]) {
                    this.discountGranted = true;
                    this.discountGrantedDisabled = true;
                }
            }
        );
    }
}
