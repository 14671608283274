import userApi from "@/api/userApi";
import { User } from "@/models/users/User";
import { RootState } from "@/store";
import { ActionTree, Commit } from "vuex";
import { UserState } from ".";
import { UserMutations } from "./mutations";
import { makeStoreRequest } from "@/services/requestUtils";
import { AxiosResponse } from "axios";

export enum UserActions {
    LoadUsers = "loadUsers",
    DeleteUserById = "deleteUserById",
    LoadUserDetailsById = "loadUserDetailsById",
    CommitUserUpdate = "commitUserUpdate",
    CreateUser = "createUser",
    GetTwoFactorRecoveryCode = "getTwoFactorRecoveryCode"
}

async function makeModuleRequest<T>(
    commit: Commit,
    callback: () => Promise<AxiosResponse<T>>
) {
    return makeStoreRequest(commit, callback, UserMutations.SetLoading);
}

const actions: ActionTree<UserState, RootState> = {
    async [UserActions.LoadUsers]({ commit }) {
        const users = await makeModuleRequest(commit, userApi.getUsers);
        commit(UserMutations.SetUsers, users);
    },
    async [UserActions.DeleteUserById]({ commit }, payload: number) {
        await makeModuleRequest(commit, () => userApi.deleteUser(payload));
        commit(UserMutations.RemoveUserById, payload);
    },
    async [UserActions.LoadUserDetailsById]({ commit }, payload: number) {
        const user = await makeModuleRequest(commit, () =>
            userApi.getUserDetails(payload)
        );
        commit(UserMutations.SetUser, user);
    },
    async [UserActions.CommitUserUpdate](
        { state, commit },
        payload?: Partial<User>
    ): Promise<void> {
        const userToUpdate = payload ?? state.user;
        if (userToUpdate) {
            await makeModuleRequest(commit, () =>
                userApi.updateUser(userToUpdate)
            );
            this.commit(UserMutations.SetUser, payload);
        }
    },
    async [UserActions.CreateUser](
        { commit },
        payload: Partial<User>
    ): Promise<void> {
        await makeModuleRequest(commit, () => userApi.createUser(payload));
        this.commit(UserMutations.SetUser, payload);
    },
    async [UserActions.GetTwoFactorRecoveryCode]({ commit }, payload: number) {
        return await makeModuleRequest(commit, () =>
            userApi.getTwoFactorRecoveryCode(payload)
        );
    }
};

export default actions;
