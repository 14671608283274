import {
    EXCLUDE_MARKET,
    MARKETS,
    ROLE_TYPE,
    USER_MANAGE
} from "@/consts/customClaims";
import { User } from "@/models/users/User";
import store from "@/store";
import { AccountGetters } from "@/store/modules/account/getters";

const roleClaim =
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
const IFM_ADMIN_ROLE = "IFM Admin";
const BMW_ADMIN_ROLE = "BMW Admin";

export function isUserInRole(role: string, user?: User): boolean {
    user = user ?? getCurrentUser();
    if (user) {
        return (
            user.role === role ||
            (user.claims?.[roleClaim]?.includes(role) ?? false)
        );
    }
    return false;
}

export function isUserInRoles(roles: string[], user?: User): boolean {
    user = user ?? getCurrentUser();
    if (user) {
        for (const role of roles) {
            if (isUserInRole(role, user)) {
                return true;
            }
        }
    }
    return false;
}

export function isUserInRoleTypes(types: string[], user?: User): boolean {
    user = user ?? getCurrentUser();
    if (user) {
        for (const type of types) {
            if (user.claims?.[ROLE_TYPE].find((r) => r === type)) {
                return true;
            }
        }
    }
    return false;
}

export function userHasClaims(claims: string[], user?: User): boolean {
    user = user ?? getCurrentUser();
    if (user) {
        for (const claim of claims) {
            if (!user.claims?.[claim]) {
                return false;
            }
        }
        return true;
    }
    return false;
}

export function getUserClaimValue(claim: string): string[] | null {
    const user = getCurrentUser();
    return user?.claims?.[claim] ?? null;
}

export function canUserManageRole(role: string, user?: User): boolean {
    user = user ?? getCurrentUser();
    if (user && user.claims) {
        return user.claims?.[USER_MANAGE]?.find((r) => r == role) !== undefined;
    }

    return false;
}

export function isAdmin(user?: User): boolean {
    user = user ?? getCurrentUser();

    if (!user?.claims?.[ROLE_TYPE]) return false;

    return (
        user.claims?.[ROLE_TYPE].find(
            (r) => r === IFM_ADMIN_ROLE || r === BMW_ADMIN_ROLE
        ) !== undefined
    );
}

export function isMarketUser(user?: User): boolean {
    user = user ?? getCurrentUser();
    return user?.claims?.[MARKETS]?.find((c) => c === "Custom") !== null;
}

export function canManageMarket(market: string, user?: User): boolean {
    user = user ?? getCurrentUser();
    if (user) {
        const excludeClaims = user.claims?.[EXCLUDE_MARKET];
        if (excludeClaims) {
            for (const claim of excludeClaims) {
                if (claim === market) {
                    return false;
                }
            }
        }
        const marketClaims = user.claims?.[MARKETS];
        if (marketClaims) {
            for (const claim of marketClaims) {
                if (claim === market || claim === "All") {
                    return true;
                }
            }
        }
    }
    return false;
}

export function isUserAuthorized(): boolean {
    return getCurrentUser() !== null;
}

export function getCurrentUser(): User | undefined {
    return store.getters["account/" + AccountGetters.Session]?.User ?? null;
}
