import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { MonthlyReportState } from ".";

export enum MonthlyReportGetters {
    MonthlyReport = "monthlyReport",
    IsLoading = "isLoading",
    Errors = "errors"
}

const getters: GetterTree<MonthlyReportState, RootState> = {
    [MonthlyReportGetters.MonthlyReport](state) {
        return state.monthlyReport;
    },
    [MonthlyReportGetters.IsLoading](state) {
        return state.loading;
    },
    [MonthlyReportGetters.Errors](state) {
        return state.errors;
    }
};

export default getters;
