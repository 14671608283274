import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Case } from "@/models/cases/Case";

export interface SolutionState {
    case: Case | null;
    errors: string[] | string | null;
    loading: boolean;
}

const solutionState: SolutionState = {
    case: null,
    errors: null,
    loading: false
};

const solution: Module<SolutionState, RootState> = {
    namespaced: true,
    state: solutionState,
    getters: getters,
    mutations: mutations,
    actions: actions
};

export const solutionModule = createNamespacedHelpers("solution");

export default solution;
