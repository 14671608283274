import { Contact } from "@/models/cases/Contact";
import { ContactListDto } from "@/models/Gdpr/ContactListDto";
import { ContactCar } from "@/models/cases/ContactCar";
import api from ".";
import { PaginationInfo } from "@/models/PaginationInfo";
import { PaginationResult } from "@/models/PaginationResult";
import { ContactFilter } from "@/models/Gdpr/ContactFilter";
import { AxiosResponse } from "axios";

function createContact(contact: Contact): Promise<AxiosResponse<number>> {
    return api.post("contacts", contact);
}

function getContactsWithPagination(
    payload: PaginationInfo & ContactFilter
): Promise<AxiosResponse<PaginationResult<ContactListDto>>> {
    return api.get("contacts/list", { params: payload });
}

function updateContact(contact: Contact): Promise<AxiosResponse<void>> {
    return api.put(`contacts/${contact.contactId}`, contact);
}

function getContact(contactId: number): Promise<AxiosResponse<Contact>> {
    return api.get(`contacts/${contactId}`);
}

function searchContact(
    firstName?: string,
    lastName?: string,
    email?: string,
    count = 20
): Promise<AxiosResponse<Contact[]>> {
    return api.get("contacts/search", {
        params: { firstName, lastName, email, count }
    });
}

function createContactCar(
    contactId: number,
    contactCar: ContactCar
): Promise<AxiosResponse<number>> {
    return api.post(`contacts/${contactId}/cars`, contactCar);
}

function updateContactCar(
    contactId: number,
    contactCar: ContactCar
): Promise<AxiosResponse<void>> {
    return api.put(
        `contacts/${contactId}/cars/${contactCar.contactCarId}`,
        contactCar
    );
}

function deleteContactCar(
    contactId: number,
    contactCarId: number
): Promise<AxiosResponse<void>> {
    return api.delete(`contacts/${contactId}/cars/${contactCarId}`);
}

function deleteContactById(contactId: number): Promise<AxiosResponse<void>> {
    return api.delete(`contacts/${contactId}`);
}

export default {
    createContact,
    updateContact,
    searchContact,
    getContact,
    createContactCar,
    updateContactCar,
    deleteContactCar,
    getContactsWithPagination,
    deleteContactById
};
