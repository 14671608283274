
import Vue from "vue";
import { Component, ModelSync, Prop } from "vue-property-decorator";
import dateFormat from "dateformat";
import { formatToISODateTime } from "@/services/dateFormatUtils";

@Component({
    inheritAttrs: false
})
export default class DatePicker extends Vue {
    @ModelSync("date", "change", { type: [String], required: false })
    public dateValue!: string | undefined;

    @Prop({ type: Boolean, default: false })
    public readonly showTime!: boolean;

    @Prop({ type: Boolean, default: false })
    public readonly allowFieldInput!: boolean;

    @Prop({ type: [String], required: false })
    public readonly minDate!: string | undefined;

    @Prop({ type: [String], required: false })
    public readonly maxDate!: string | undefined;

    @Prop({ type: Boolean, default: false })
    public readonly requiredField!: boolean;

    protected menu = false;

    get isoDateValue(): string {
        if (this.dateValue) {
            return formatToISODateTime(this.dateValue);
        }
        return "";
    }

    get displayValue(): string {
        if (this.isoDateValue) {
            if (!this.showTime) {
                return dateFormat(
                    new Date(this.isoDateValue),
                    "dd.mm.yyyy",
                    true
                );
            } else {
                return dateFormat(
                    new Date(this.isoDateValue),
                    "dd.mm.yyyy HH:MM:ss"
                );
            }
        }
        return "";
    }

    get value(): string {
        if (this.isoDateValue) {
            return dateFormat(this.isoDateValue, "yyyy-mm-dd", true);
        }
        return "";
    }
    set value(value: string) {
        this.dateValue = dateFormat(value, "yyyy-mm-dd", true);
    }

    get inputDate(): string {
        return this.dateValue
            ? dateFormat(this.dateValue, "yyyy-mm-dd", true)
            : "";
    }
    set inputDate(value: string) {
        if (value) {
            const dateSegments = value.split("-");
            if (dateSegments.length !== 3) return;

            const years = dateSegments[0];
            if (years.length !== 4) return;

            this.dateValue = dateFormat(value, "yyyy-mm-dd", true);
        }
    }

    get minDateValue(): string | undefined {
        if (this.minDate) {
            return dateFormat(this.minDate, "yyyy-mm-dd", true);
        }
        return undefined;
    }

    get maxDateValue(): string | undefined {
        if (this.maxDate) {
            return dateFormat(this.maxDate, "yyyy-mm-dd", true);
        }
        return undefined;
    }

    get dateRules(): undefined | [(v: string) => boolean] {
        return this.requiredField ? [(v: string) => !!v] : undefined;
    }
}
