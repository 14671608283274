import { MutationTree } from "vuex";
import { NotificationsState } from ".";

export enum NotificationsMutation {
    SetShowing = "setShowing",
    SetNotificationsMessage = "setNotificationsMessage",
    SetColour = "setColour"
}

const mutations: MutationTree<NotificationsState> = {
    [NotificationsMutation.SetShowing](state, payload: boolean) {
        state.isShowing = payload;
    },
    [NotificationsMutation.SetNotificationsMessage](state, payload: string) {
        state.notificationMessage = payload;
    },
    [NotificationsMutation.SetColour](state, payload: string) {
        state.color = payload;
    }
};

export default mutations;
