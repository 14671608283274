import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { BillingAccountState } from ".";

export enum BillingAccountGetters {
    AccountInfo = "accountInfo",
    IsLoading = "isLoading",
    Errors = "errors",
    Year = "year",
    Month = "month"
}

const getters: GetterTree<BillingAccountState, RootState> = {
    [BillingAccountGetters.AccountInfo](state) {
        return state.accountInfo;
    },
    [BillingAccountGetters.IsLoading](state) {
        return state.isLoading;
    },
    [BillingAccountGetters.Errors](state) {
        return state.errors;
    },
    [BillingAccountGetters.Year](state) {
        return state.year;
    },
    [BillingAccountGetters.Month](state) {
        return state.month;
    }
};

export default getters;
