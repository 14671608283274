import { ActionTree, Commit } from "vuex";
import { makeStoreRequest } from "@/services/requestUtils";
import { BlacklistState } from "@/store/modules/blacklist/index";
import { RootState } from "@/store";
import { PaginationInfo } from "@/models/PaginationInfo";
import { BlacklistFilter } from "@/models/blacklist/BlacklistFilter";
import blacklistApi from "@/api/blacklistApi";
import { Blacklist } from "@/models/blacklist/Blacklist";
import { BlacklistMutation } from "@/store/modules/blacklist/mutations";
import { AxiosResponse } from "axios";

export enum BlacklistActions {
    CreateBlacklist = "createBlacklist",
    LoadBlacklistById = "loadBlacklistById",
    UpdateBlacklist = "updateBlacklist",
    LoadBlacklistsWithPagination = "loadBlacklistsWithPagination",
    DeleteBlacklist = "deleteBlacklist"
}

async function makeModuleRequest<T>(
    commit: Commit,
    callback: () => Promise<AxiosResponse<T>>
) {
    return makeStoreRequest(commit, callback, BlacklistMutation.SetLoading);
}

const actions: ActionTree<BlacklistState, RootState> = {
    async [BlacklistActions.CreateBlacklist]({ commit }, payload: Blacklist) {
        const blacklistId = await makeModuleRequest(commit, () =>
            blacklistApi.createBlacklist(payload)
        );

        commit(BlacklistMutation.SetBlacklist, {
            ...payload,
            blacklistId
        });
        commit(BlacklistMutation.AddBlacklist, {
            ...payload,
            blacklistId
        });
    },
    async [BlacklistActions.LoadBlacklistsWithPagination](
        { commit },
        payload: {
            paginationInfo: PaginationInfo;
            blacklistFilter: BlacklistFilter;
        }
    ) {
        const paginationResult = await makeModuleRequest(commit, () =>
            blacklistApi.getBlacklistWithPagination(
                payload.paginationInfo,
                payload.blacklistFilter
            )
        );
        commit(BlacklistMutation.SetPaginationResult, paginationResult);
    },
    async [BlacklistActions.LoadBlacklistById]({ commit }, payload: number) {
        const blacklist = await makeModuleRequest(commit, () =>
            blacklistApi.getBlacklistById(payload)
        );
        commit(BlacklistMutation.SetBlacklist, blacklist);
    },
    async [BlacklistActions.UpdateBlacklist]({ commit }, payload: Blacklist) {
        const updatedBlacklist = await makeModuleRequest(commit, () =>
            blacklistApi.updateBlacklist(payload)
        );
        commit(BlacklistMutation.SetBlacklist, payload);
        commit(BlacklistMutation.UpdateBlackList, {
            ...payload,
            updatedBlacklist
        });
    },
    async [BlacklistActions.DeleteBlacklist]({ commit }, payload: number) {
        await makeModuleRequest(commit, () =>
            blacklistApi.deleteBlacklistById(payload)
        );
        commit(BlacklistMutation.RemoveBlacklist, payload);
    }
};

export default actions;
