import { User } from "@/models/users/User";
import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export interface UserState {
    users: User[];
    user: Partial<User> | null;
    errors: string[] | null;
    loading: boolean;
    isEdited: boolean;
}

const userState: UserState = {
    users: [],
    user: null,
    errors: null,
    loading: false,
    isEdited: false
};

const user: Module<UserState, RootState> = {
    namespaced: true,
    state: userState,
    getters: getters,
    mutations: mutations,
    actions: actions
};

export const userModule = createNamespacedHelpers("user");

export default user;
