import billingApi from "@/api/billingApi";
import { makeStoreRequest } from "@/services/requestUtils";
import { RootState } from "@/store";
import { AxiosResponse } from "axios";
import { ActionTree, Commit } from "vuex";
import { BillingState } from ".";
import { BillingMutation } from "./mutations";

export enum BillingActions {
    LoadMonthBillingData = "loadMonthBillingData"
}

async function makeModuleRequest<T>(
    commit: Commit,
    callback: () => Promise<AxiosResponse<T>>
) {
    return makeStoreRequest(commit, callback, BillingMutation.SetLoading);
}

const actions: ActionTree<BillingState, RootState> = {
    async [BillingActions.LoadMonthBillingData]({ commit, state }) {
        const request = async () => {
            return await billingApi.getMonthBillingData(
                state.year,
                state.month
            );
        };

        const accountInfo = await makeModuleRequest(commit, request);
        commit(BillingMutation.SetMonthBillingData, accountInfo);
    }
};

export default actions;
