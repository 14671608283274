import { Case } from "@/models/cases/Case";
import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { SolutionState } from ".";

export enum SolutionGetters {
    Errors = "errors",
    Loading = "loading",
    Case = "case"
}

const getters: GetterTree<SolutionState, RootState> = {
    [SolutionGetters.Loading](state): boolean {
        return state.loading;
    },
    [SolutionGetters.Errors](state): string[] | string | null {
        return state.errors;
    },
    [SolutionGetters.Case](state): Case | null {
        return state.case;
    }
};

export default getters;
