import { User } from "@/models/users/User";
import { AxiosResponse } from "axios";
import api from ".";

function getUserByMarket(marketId: number): Promise<AxiosResponse<User[]>> {
    return api.get(`markets/${marketId}/users`);
}

async function getUsers(): Promise<AxiosResponse<User[]>> {
    return api.get("/users");
}

async function getUserDetails(userId: number): Promise<AxiosResponse<User>> {
    return api.get("/user/" + userId);
}

async function getIKAMS(): Promise<AxiosResponse<User[]>> {
    return api.get("/users/ikams");
}

async function updateUser(user: Partial<User>): Promise<AxiosResponse<void>> {
    return api.post("/user/" + user.id, user);
}

async function deleteUser(userId: number): Promise<AxiosResponse<void>> {
    return api.delete("/user/" + userId);
}

async function createUser(user: Partial<User>): Promise<AxiosResponse<void>> {
    return api.post("/users/", user);
}

async function getTwoFactorRecoveryCode(
    userId: number
): Promise<AxiosResponse<string>> {
    return api.get(`/user/${userId}/code`);
}

export default {
    getUserByMarket,
    getIKAMS,
    getUsers,
    getUserDetails,
    updateUser,
    deleteUser,
    createUser,
    getTwoFactorRecoveryCode
};
