
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import AppIcon from "@/components/AppIcon";

@Component({
    components: { AppIcon }
})
export default class BaseButton extends Vue {
    @Prop({ type: Boolean, default: false })
    public readonly dark!: boolean;

    @Prop({ type: Boolean, default: false })
    public readonly simple!: boolean;

    @Prop({ type: Boolean, default: false })
    public readonly block!: boolean;

    @Prop({ type: String, default: "" })
    public readonly link!: string;

    @Prop({ type: Boolean, default: false })
    public readonly submit!: boolean;

    @Prop({ type: Boolean, default: false })
    public readonly disabled!: boolean;

    @Prop({ type: String, default: "" })
    public readonly icon!: string;

    @Prop({ type: String, default: "" })
    public readonly iconColor!: string;

    @Prop({ type: Number, required: false })
    public readonly width!: number | undefined;

    @Prop({ type: Boolean, default: false })
    public readonly success!: boolean;

    @Prop({ type: Boolean, default: false })
    public readonly danger!: boolean;

    get classes(): string[] {
        const classes = ["btn"];

        if (this.dark) classes.push("btn-dark");
        else if (this.simple) classes.push("btn-simple");
        else classes.push("btn-primary");

        if (this.block) classes.push("btn-block");
        if (this.success) classes.push("btn-success");
        if (this.danger) classes.push("btn-danger");
        if (this.disabled) classes.push("disabled");

        return classes;
    }

    get styles(): unknown | null {
        if (this.width) {
            return { width: `${this.width}px` };
        }
        return null;
    }
}
