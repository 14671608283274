import { MutationTree } from "vuex";
import { RouteState } from "./index";
import { Route } from "vue-router";

export enum RouteMutation {
    SetPreviousRoute = "SetPreviousRoute"
}

const mutations: MutationTree<RouteState> = {
    [RouteMutation.SetPreviousRoute](state, payload: Route) {
        state.previousRoute = payload;
    }
};

export default mutations;
