import router from "@/router";
import { Routes } from "@/router/routes";
import axios, { AxiosError } from "axios";

const baseUrl = process.env.VUE_APP_API_BASE_URL ?? "/api/v1.0/";

const instance = axios.create({ baseURL: baseUrl });

instance.interceptors.response.use(
    (response) => response,
    (error) => {
        const axiosError = error as AxiosError;
        const response = axiosError.response;
        if (response) {
            if (response.status === 401) {
                response.data = "Authentication required";

                if (router.currentRoute.path !== Routes.Login)
                    router.push(
                        Routes.Login +
                            "?redirecturl=" +
                            router.currentRoute.path
                    );
            } else if (response.status === 403 || response.status === 500) {
                router.push(Routes.Error + response.status);
            }
        }

        return Promise.reject(axiosError);
    }
);

export default instance;
