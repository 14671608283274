import marketApi from "@/api/marketApi";
import { Market } from "@/models/markets/Market";
import { makeStoreRequest } from "@/services/requestUtils";
import { RootState } from "@/store";
import { AxiosResponse } from "axios";
import { ActionTree, Commit } from "vuex";
import { MarketState } from ".";
import { MarketMutation } from "./mutations";

export enum MarketActions {
    LoadMarkets = "loadMarkets",
    LoadMarketById = "loadMarketById",
    CreateMarket = "createMarket",
    UpdateMarket = "updateMarket",
    DeleteMarket = "deleteMarket",
    LoadInfo = "loadInfo",
    LoadRegions = "loadRegions",
    LoadCountries = "loadCountries"
}

async function makeModuleRequest<T>(
    commit: Commit,
    callback: () => Promise<AxiosResponse<T>>
) {
    return makeStoreRequest(commit, callback, MarketMutation.SetLoading);
}

const actions: ActionTree<MarketState, RootState> = {
    async [MarketActions.LoadMarkets]({ commit }) {
        const markets = await makeModuleRequest(commit, () =>
            marketApi.getMarkets()
        );
        commit(MarketMutation.SetMarkets, markets);
    },
    async [MarketActions.LoadMarketById]({ commit }, payload: number) {
        const market = await makeModuleRequest(commit, () =>
            marketApi.getMarketById(payload)
        );
        commit(MarketMutation.SetMarket, market);
    },
    async [MarketActions.LoadInfo]({ commit }) {
        const info = await makeModuleRequest(commit, () => marketApi.getInfo());
        commit(MarketMutation.SetInfo, info);
    },
    async [MarketActions.CreateMarket]({ commit }, payload: Market) {
        const marketId = await makeModuleRequest(commit, () =>
            marketApi.createMarket(payload)
        );
        commit(MarketMutation.SetMarket, { ...payload, marketId });
    },
    async [MarketActions.UpdateMarket]({ commit }, payload: Market) {
        await makeModuleRequest(commit, () => marketApi.updateMarket(payload));
        commit(MarketMutation.SetMarket, payload);
    },
    async [MarketActions.DeleteMarket]({ commit }, payload: number) {
        await makeModuleRequest(commit, () => marketApi.deleteMarket(payload));
        commit(MarketMutation.RemoveMarket, payload);
    },
    async [MarketActions.LoadRegions]({ commit }) {
        const regions = await makeModuleRequest(commit, () =>
            marketApi.getRegions()
        );
        commit(MarketMutation.SetRegions, regions);
    },
    async [MarketActions.LoadCountries]({ commit }) {
        const countries = await makeModuleRequest(commit, () =>
            marketApi.getCountries()
        );
        commit(MarketMutation.SetCountries, countries);
    }
};

export default actions;
