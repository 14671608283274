import { Country } from "@/models/markets/Country";
import { Market } from "@/models/markets/Market";
import { MarketInfo } from "@/models/markets/MarketInfo";
import { AxiosResponse } from "axios";
import api from ".";

function getMarkets(): Promise<AxiosResponse<Market[]>> {
    return api.get<Market[]>("markets");
}

function getMarketById(marketId: number): Promise<AxiosResponse<Market>> {
    return api.get<Market>(`markets/${marketId}`);
}

function createMarket(market: Market): Promise<AxiosResponse<number>> {
    return api.post<number>("markets", market);
}

function updateMarket(market: Market): Promise<AxiosResponse<void>> {
    return api.put(`markets/${market.marketId}`, market);
}

function deleteMarket(marketId: number): Promise<AxiosResponse<void>> {
    return api.delete(`markets/${marketId}`);
}

function getInfo(): Promise<AxiosResponse<MarketInfo>> {
    return api.get<MarketInfo>("markets/info");
}

function getRegions(): Promise<AxiosResponse<string[]>> {
    return api.get<string[]>("markets/regions");
}

function getCountries(): Promise<AxiosResponse<Country[]>> {
    return api.get<Country[]>("markets/countries");
}

export default {
    getMarkets,
    getMarketById,
    createMarket,
    updateMarket,
    deleteMarket,
    getInfo,
    getRegions,
    getCountries
};
