
import Vue from "vue";
import Component from "vue-class-component";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import { monthlyReportModule } from "@/store/modules/monthlyReport";
import { MonthlyReportGetters } from "@/store/modules/monthlyReport/getters";
import { MonthlyReportActions } from "@/store/modules/monthlyReport/actions";
import { MonthlyReport } from "@/models/reports/MonthlyReport";
import MonthlyReportDialog from "@/components/MonthlyReportDialog";
import MonthlyReportContent from "@/components/MonthlyReportContent";

@Component({
    components: {
        ConfirmationDialog,
        MonthlyReportDialog,
        MonthlyReportContent
    },
    computed: {
        ...monthlyReportModule.mapGetters({
            monthlyReportGetter: MonthlyReportGetters.MonthlyReport,
            isLoading: MonthlyReportGetters.IsLoading
        })
    },
    methods: {
        ...monthlyReportModule.mapActions({
            loadMonthlyReport: MonthlyReportActions.LoadMonthlyReport,
            updateMonthlyReport: MonthlyReportActions.UpdateMonthlyReport
        })
    }
})
export default class MonthlyReportPage extends Vue {
    private readonly loadMonthlyReport!: () => Promise<void>;
    private readonly updateMonthlyReport!: (
        monthlyReport: MonthlyReport
    ) => Promise<void>;

    protected readonly monthlyReportGetter!: MonthlyReport;
    protected readonly isLoading!: boolean;

    public englishDialogVisible = false;
    public germanDialogVisible = false;

    public englishContent = "";
    public germanContent = "";
    public englishSignature = "";
    public germanSignature = "";

    async saveEnglishContent(
        content: string,
        signature: string
    ): Promise<void> {
        this.englishContent = content;
        this.englishSignature = signature;
        await this.confirmMonthlyReportChange();
    }

    async saveGermanContent(content: string, signature: string): Promise<void> {
        this.germanContent = content;
        this.germanSignature = signature;
        await this.confirmMonthlyReportChange();
    }

    async confirmMonthlyReportChange(): Promise<void> {
        const monthlyReport = {
            englishContent: this.englishContent,
            germanContent: this.germanContent,
            englishSignature: this.englishSignature,
            germanSignature: this.germanSignature
        };
        await this.updateMonthlyReport(monthlyReport);
        this.englishDialogVisible = false;
        this.germanDialogVisible = false;
    }
    async created(): Promise<void> {
        await this.loadMonthlyReport();
        if (this.monthlyReportGetter) {
            this.englishContent = this.monthlyReportGetter.englishContent ?? "";
            this.germanContent = this.monthlyReportGetter.germanContent ?? "";
            this.englishSignature =
                this.monthlyReportGetter.englishSignature ?? "";
            this.germanSignature =
                this.monthlyReportGetter.germanSignature ?? "";
        }
    }
}
