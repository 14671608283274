import { Case } from "@/models/cases/Case";
import { CaseEvent } from "@/models/cases/CaseEvent";
import { CaseEventEditDetails } from "@/models/cases/CaseEventEditDetails";
import { CaseInfo } from "@/models/cases/CaseInfo";
import { PaginationInfo } from "@/models/PaginationInfo";
import { PaginationResult } from "@/models/PaginationResult";
import { Rule } from "@/models/rules/Rule";
import { AxiosResponse } from "axios";
import api from ".";
import { ListCaseFilter, SortParams } from "@/models/cases/CaseFilter";
import { CaseParentCandidate } from "../models/cases/CaseParentCandidate";
import { ForwardCaseData } from "@/models/cases/ForwardCaseData";
import { SendEmailData } from "@/models/cases/SendEmailData";
import { CaseListItem } from "@/models/cases/CaseListItem";
import { ReplyEventData } from "@/models/cases/ReplyEventData";
import LockedResult from "@/models/LockedResult";
import CaseUpdate from "@/models/cases/CaseUpdate";

function createCase(caseModel: Case): Promise<AxiosResponse<Case>> {
    return api.post("cases", caseModel);
}

function updateCase(
    caseId: number,
    caseModel: CaseUpdate
): Promise<AxiosResponse<Case>> {
    return api.put(`cases/${caseId}`, caseModel);
}

function getCaseById(caseId: number): Promise<AxiosResponse<Case>> {
    return api.get(`cases/${caseId}`);
}

function getFilteredCasesWithPagination(
    paginationInfo: PaginationInfo,
    caseFilter: ListCaseFilter
): Promise<AxiosResponse<PaginationResult<CaseListItem>>> {
    return api.post("cases/list", caseFilter, { params: paginationInfo });
}

function getInfo(): Promise<AxiosResponse<CaseInfo>> {
    return api.get("cases/info");
}

function deleteCaseById(caseId: number): Promise<AxiosResponse<void>> {
    return api.delete(`cases/${caseId}`);
}

function addNewComment(
    caseId: number,
    details: CaseEventEditDetails
): Promise<AxiosResponse<CaseEvent>> {
    const formData = getFormDataForCaseEvent(details);

    return api.post<CaseEvent>(`case/${caseId}/event`, formData);
}

function updateCaseEvent(
    caseId: number,
    caseEventId: number,
    details: CaseEventEditDetails
): Promise<AxiosResponse<CaseEvent>> {
    const formData = getFormDataForCaseEvent(details);

    return api.put<CaseEvent>(`case/${caseId}/event/${caseEventId}`, formData);
}

function getFormDataForCaseEvent(details: CaseEventEditDetails) {
    const formData = new FormData();

    formData.append("title", details.title);
    formData.append("content", details.content);
    details.files.forEach((f) => {
        formData.append("files", f);
    });
    return formData;
}

function deleteCaseEvent(
    caseId: number,
    caseEventId: number
): Promise<AxiosResponse<void>> {
    return api.delete(`case/${caseId}/event/${caseEventId}`);
}

function convertToNewCaseEvent(
    caseId: number,
    caseEventId: number
): Promise<AxiosResponse<void>> {
    return api.post(`case/${caseId}/event/${caseEventId}`);
}

function loadRulesForCase(caseId: number): Promise<AxiosResponse<Rule[]>> {
    return api.get(`case/${caseId}/rules`);
}

function duplicate(caseId: number): Promise<AxiosResponse<Case>> {
    return api.post(`cases/${caseId}/duplicate`);
}

function searchParents(
    search: string,
    count: number
): Promise<AxiosResponse<CaseParentCandidate[]>> {
    return api.get(`cases/search-parents`, { params: { search, count } });
}

function updateParent(
    caseId: number,
    parentInternalId: number
): Promise<AxiosResponse<Case>> {
    return api.put(`cases/${caseId}/parent`, { internalId: parentInternalId });
}

function deleteParent(caseId: number): Promise<AxiosResponse<void>> {
    return api.delete(`cases/${caseId}/parent`);
}

function integrate(
    caseId: number,
    targetInternalId: number
): Promise<AxiosResponse<Case>> {
    return api.post(`cases/${caseId}/integrate`, {
        internalId: targetInternalId
    });
}

function markAsSpam(caseId: number): Promise<AxiosResponse<Case>> {
    return api.post(`cases/${caseId}/spam`);
}

function stopReminder(caseId: number): Promise<AxiosResponse<Case>> {
    return api.post(`cases/${caseId}/stop-reminder`);
}

function isReminderProcessAvailable(
    caseId: number
): Promise<AxiosResponse<boolean>> {
    return api.get<boolean>(`case/${caseId}/forward/reminder-process`);
}

function forwardCase(
    caseId: number,
    forwardData: ForwardCaseData
): Promise<AxiosResponse<Case>> {
    return api.post(`case/${caseId}/forward`, forwardData);
}

function getForwardEmailPreviewForCase(
    caseId: number,
    forwardData: ForwardCaseData
): Promise<AxiosResponse<string>> {
    return api.get<string>(`case/${caseId}/forward/preview`, {
        params: forwardData
    });
}

function sendEmail(
    caseId: number,
    sendEmailData: SendEmailData
): Promise<AxiosResponse<Case>> {
    return api.post(`case/${caseId}/send-email`, sendEmailData);
}

function replyCaseEvent(
    caseId: number,
    replyCaseEventData: ReplyEventData
): Promise<AxiosResponse<Case>> {
    return api.post(`case/${caseId}/reply`, replyCaseEventData);
}

function getCaseExportBlob(
    caseFilter: ListCaseFilter
): Promise<AxiosResponse<Blob>> {
    return api.post("cases/export", caseFilter, { responseType: "blob" });
}

function checkExportConditions(
    caseFilter: ListCaseFilter
): Promise<AxiosResponse<void>> {
    return api.post("cases/export/check-conditions", caseFilter);
}

function lock(caseId: number): Promise<AxiosResponse<LockedResult>> {
    return api.post(`cases/${caseId}/lock`);
}

function unlock(caseId: number): Promise<AxiosResponse<void>> {
    return api.delete(`cases/${caseId}/lock`);
}

function deleteAttachment(attachmentId: number): Promise<AxiosResponse<void>> {
    return api.delete(`cases/attachments/${attachmentId}`);
}

function getFavoriteCasesForCurrentUser(): Promise<AxiosResponse<number[]>> {
    return api.get("cases/favorite");
}

function getFavoriteCaseListForCurrentUser(
    paginationInfo: PaginationInfo,
    sortParams?: SortParams
): Promise<AxiosResponse<PaginationResult<CaseListItem>>> {
    return api.get("cases/favorite/list", {
        params: { ...paginationInfo, ...sortParams }
    });
}

function markCaseAsFavorite(caseId: number): Promise<AxiosResponse<void>> {
    return api.post(`cases/favorite/${caseId}/mark`);
}

function unmarkCaseAsFavorite(caseId: number): Promise<AxiosResponse<void>> {
    return api.post(`cases/favorite/${caseId}/unmark`);
}

function closeRelated(caseId: number): Promise<AxiosResponse<Case>> {
    return api.post(`cases/${caseId}/close-related`);
}

function getReplyTemplate(): Promise<AxiosResponse<string>> {
    return api.get("case/reply/get-template");
}

export default {
    createCase,
    updateCase,
    getCaseById,
    getInfo,
    addNewComment,
    updateCaseEvent,
    deleteCaseEvent,
    convertToNewCaseEvent,
    loadRulesForCase,
    getFilteredCasesWithPagination,
    deleteCaseById,
    duplicate,
    searchParents,
    updateParent,
    deleteParent,
    integrate,
    markAsSpam,
    stopReminder,
    isReminderProcessAvailable,
    forwardCase,
    sendEmail,
    getForwardEmailPreviewForCase,
    replyCaseEvent,
    getCaseExportBlob,
    checkExportConditions,
    lock,
    unlock,
    deleteAttachment,
    getFavoriteCasesForCurrentUser,
    getFavoriteCaseListForCurrentUser,
    markCaseAsFavorite,
    unmarkCaseAsFavorite,
    closeRelated,
    getReplyTemplate
};
