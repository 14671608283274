import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { CaseState } from ".";
import { orderBy, uniqBy } from "lodash";
import { Case } from "@/models/cases/Case";
import { CaseEvent } from "@/models/cases/CaseEvent";
import { Attachment } from "@/models/Attachment";
import { Rule } from "@/models/rules/Rule";

export enum CaseGetters {
    Case = "case",
    IsLoading = "isLoading",
    Events = "events",
    Attachments = "attachments",
    Rules = "caseRules",
    ReplyTemplate = "replyTemplate"
}

const getters: GetterTree<CaseState, RootState> = {
    [CaseGetters.Case](state): Case {
        return state.case;
    },
    [CaseGetters.IsLoading](state): boolean {
        return state.loading;
    },
    [CaseGetters.Events](state): CaseEvent[] {
        return orderBy(
            state.case.caseEvents,
            (ce) => ce.createdAtDatetime,
            "desc"
        );
    },
    [CaseGetters.Attachments](state): Attachment[] {
        const attachments = state.case.caseEvents.flatMap(
            (ce) => ce.attachments ?? []
        );
        return uniqBy(attachments, (a) => a.attachmentId);
    },
    [CaseGetters.Rules](state): Rule[] {
        return state.rules;
    },
    [CaseGetters.ReplyTemplate](state): string {
        return state.replyTemplate;
    }
};

export default getters;
