import { makeStoreRequest } from "@/services/requestUtils";
import { RootState } from "@/store";
import { ActionTree, Commit } from "vuex";
import { RuleState } from ".";
import { RuleMutations } from "./mutations";
import ruleApi from "@/api/ruleApi";
import { PaginationInfo } from "@/models/PaginationInfo";
import { Rule } from "@/models/rules/Rule";
import { AxiosResponse } from "axios";

export enum RuleActions {
    LoadRuleById = "loadRuleById",
    LoadRules = "loadRules",
    LoadInfo = "loadInfo",
    CreateRule = "createRule",
    UpdateRule = "updateRule",
    DeleteRule = "deleteRule"
}

async function makeModuleRequest<T>(
    commit: Commit,
    callback: () => Promise<AxiosResponse<T>>
) {
    return makeStoreRequest(commit, callback, RuleMutations.SetLoading);
}

const actions: ActionTree<RuleState, RootState> = {
    async [RuleActions.LoadRuleById]({ commit }, payload: number) {
        const rule = await makeModuleRequest(commit, () =>
            ruleApi.getRuleById(payload)
        );
        commit(RuleMutations.SetRule, rule);
    },
    async [RuleActions.LoadRules](
        { commit },
        payload: {
            info: PaginationInfo;
            ruleTypeId?: number;
            marketId?: number;
        }
    ) {
        const rules = await makeModuleRequest(commit, () =>
            ruleApi.getRules(payload.info, payload.ruleTypeId, payload.marketId)
        );
        commit(RuleMutations.SetRules, rules);
    },
    async [RuleActions.LoadInfo]({ commit }) {
        const info = await makeModuleRequest(commit, () => ruleApi.getInfo());
        commit(RuleMutations.SetInfo, info);
    },
    async [RuleActions.CreateRule]({ commit }, payload: Rule) {
        const rule = await makeModuleRequest(commit, () =>
            ruleApi.createRule(payload)
        );
        commit(RuleMutations.SetRule, rule);
    },
    async [RuleActions.UpdateRule]({ commit }, payload: Rule) {
        const rule = await makeModuleRequest(commit, () =>
            ruleApi.updateRule(payload)
        );
        commit(RuleMutations.SetRule, rule);
    },
    async [RuleActions.DeleteRule]({ commit }, payload: number) {
        await makeModuleRequest(commit, () => ruleApi.deleteRule(payload));
    }
};

export default actions;
