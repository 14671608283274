import {
    PostQualification,
    PostQualificationCreate,
    PostQualificationUpdate
} from "@/models/postQualification/PostQualification";
import { AxiosResponse } from "axios";
import api from ".";

const API = "post-qualification";

function readPostQualification(
    token: string
): Promise<AxiosResponse<PostQualification>> {
    return api.get(`${API}/${token}`);
}

function createPostQualification(
    postQualification: PostQualificationCreate
): Promise<AxiosResponse<number>> {
    return api.post(API, postQualification);
}

function updatePostQualificationContact(
    token: string,
    postQualification: PostQualificationUpdate
): Promise<AxiosResponse> {
    return api.put(`${API}/${token}`, postQualification);
}

export default {
    readPostQualification,
    createPostQualification,
    updatePostQualificationContact
};
