import caseApi from "@/api/caseApi";
import { Case } from "@/models/cases/Case";
import { PaginationInfo } from "@/models/PaginationInfo";
import { makeStoreRequest } from "@/services/requestUtils";
import { RootState } from "@/store";
import { ActionTree, Commit } from "vuex";
import { CasesState } from ".";
import { CasesMutation } from "./mutations";
import { ListCaseFilter, SortParams } from "@/models/cases/CaseFilter";
import { AxiosResponse } from "axios";

export enum CasesActions {
    LoadFilteredCasesWithPagination = "loadFilteredCasesWithPagination",
    LoadFilteredFavoriteCasesWithPagination = "loadFilteredFavoriteCasesWithPagination",
    LoadFavoriteCasesForUser = "loadFavoriteCasesForUser",
    CreateCase = "createCase",
    DeleteCase = "deleteCase",
    LoadInfo = "loadInfo",
    Unlock = "unlock",
    MarkCaseAsFavorite = "markCaseAsFavorite",
    UnmarkCaseAsFavorite = "UnmarkCaseAsFavorite"
}

async function makeModuleRequest<T>(
    commit: Commit,
    callback: () => Promise<AxiosResponse<T>>
): Promise<T> {
    return await makeStoreRequest(commit, callback, CasesMutation.SetLoading);
}

const actions: ActionTree<CasesState, RootState> = {
    [CasesActions.LoadInfo]({ commit, state }): Promise<void> {
        if (state.infoLoadingPromise) return state.infoLoadingPromise;

        const promise = makeModuleRequest(commit, caseApi.getInfo)
            .then((result) => commit(CasesMutation.SetInfo, result))
            .catch((error) => {
                commit(CasesMutation.SetInfoLoadingPromise, null);
                throw error;
            });
        commit(CasesMutation.SetInfoLoadingPromise, promise);
        return promise;
    },
    async [CasesActions.CreateCase]({ commit }, payload: Case) {
        return await makeModuleRequest(commit, () =>
            caseApi.createCase(payload)
        );
    },
    async [CasesActions.LoadFilteredCasesWithPagination](
        { commit },
        payload: { caseFilter: ListCaseFilter; paginationInfo: PaginationInfo }
    ) {
        const paginationResult = await makeModuleRequest(commit, () =>
            caseApi.getFilteredCasesWithPagination(
                payload.paginationInfo,
                payload.caseFilter
            )
        );
        commit(CasesMutation.SetPaginationResult, paginationResult);
    },
    async [CasesActions.DeleteCase]({ commit }, payload: number) {
        await makeModuleRequest(commit, () => caseApi.deleteCaseById(payload));
        commit(CasesMutation.RemoveCase, payload);
    },
    async [CasesActions.Unlock]({ commit }, payload: number) {
        await makeModuleRequest(commit, () => caseApi.unlock(payload));
    },
    async [CasesActions.LoadFilteredFavoriteCasesWithPagination](
        { commit },
        payload: { paginationInfo: PaginationInfo; sortParams?: SortParams }
    ) {
        const paginationResult = await makeModuleRequest(commit, () =>
            caseApi.getFavoriteCaseListForCurrentUser(
                payload.paginationInfo,
                payload.sortParams
            )
        );
        commit(CasesMutation.SetPaginationResult, paginationResult);
    },
    async [CasesActions.LoadFavoriteCasesForUser]({ commit }) {
        const favoriteCaseIds = await makeModuleRequest(commit, () =>
            caseApi.getFavoriteCasesForCurrentUser()
        );
        commit(CasesMutation.SetFavoriteCases, favoriteCaseIds);
    },
    async [CasesActions.MarkCaseAsFavorite]({ commit }, payload: number) {
        await makeModuleRequest(commit, () =>
            caseApi.markCaseAsFavorite(payload)
        );
        commit(CasesMutation.MarkCaseAsFavorite, payload);
    },
    async [CasesActions.UnmarkCaseAsFavorite]({ commit }, payload: number) {
        await makeModuleRequest(commit, () =>
            caseApi.unmarkCaseAsFavorite(payload)
        );
        commit(CasesMutation.UnmarkCaseAsFavorite, payload);
    }
};

export default actions;
