import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { PostQualificationState } from ".";

export enum PostQualificationGetters {
    Token = "token"
}

const getters: GetterTree<PostQualificationState, RootState> = {
    [PostQualificationGetters.Token](state) {
        return state.token;
    }
};

export default getters;
