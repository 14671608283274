import { GetterTree } from "vuex";
import { RootState } from "@/store";
import { BlacklistState } from "@/store/modules/blacklist/index";

export enum BlacklistGetters {
    Blacklist = "blacklist",
    IsLoading = "isLoading",
    PaginationData = "paginationData",
    Errors = "errors"
}

const getters: GetterTree<BlacklistState, RootState> = {
    [BlacklistGetters.Blacklist](state) {
        return state.blacklist;
    },
    [BlacklistGetters.PaginationData](state) {
        return state.paginationData;
    },
    [BlacklistGetters.IsLoading](state) {
        return state.loading;
    },
    [BlacklistGetters.Errors](state) {
        return state.errors;
    }
};

export default getters;
