import accountApi from "@/api/accountApi";
import { RootState } from "@/store";
import { ActionTree, Commit } from "vuex";
import { AccountState, SESSION_KEY } from ".";
import { AccountMutations } from "./mutations";
import localStorageService from "@/services/localStorageService";
import { makeStoreRequest } from "@/services/requestUtils";
import { AxiosResponse } from "axios";

export enum AccountActions {
    LogUserIn = "logUserIn",
    LogUserInTwoFactor = "logUserInTwoFactor",
    LogUserOut = "logUserOut",
    VerifyLogin = "verifyLogin",
    RequestPasswordReset = "requestPasswordReset",
    ResetPassword = "resetPassword",
    ActivateAccount = "activateAccount",
    GetTwoFactorResetToken = "getTwoFactorResetToken",
    GetTwoFactorSetupCode = "getTwoFactorSetupCode",
    ActivateTwoFactorAuthentication = "activateTwoFactorAuthentication"
}

async function makeRequest<T>(
    commit: Commit,
    callback: () => Promise<AxiosResponse<T>>
) {
    return makeStoreRequest(commit, callback, AccountMutations.SetLoading);
}

const actions: ActionTree<AccountState, RootState> = {
    async [AccountActions.LogUserIn](
        { commit },
        payload: { username: string; password: string }
    ) {
        const request = async () => {
            return await accountApi.logUserIn(
                payload.username,
                payload.password
            );
        };

        const result = await makeRequest(commit, request);

        if (result != null && result.finishedSuccessfully) {
            commit(AccountMutations.SetSession, result.session);
            localStorageService.save(SESSION_KEY, result.session);
        }

        commit(AccountMutations.SetLoginResult, result);
    },
    async [AccountActions.LogUserInTwoFactor](
        { commit },
        payload: {
            twoFactorPassword: string;
        }
    ) {
        const request = async () => {
            return await accountApi.logUserInTwoFactor(
                payload.twoFactorPassword
            );
        };

        const session = await makeRequest(commit, request);
        commit(AccountMutations.SetSession, session);
        localStorageService.save(SESSION_KEY, session);
    },
    async [AccountActions.VerifyLogin]({ commit }) {
        const session = await makeRequest(commit, accountApi.verifyLogin);
        commit(AccountMutations.SetSession, session);
        localStorageService.save(SESSION_KEY, session);
    },
    async [AccountActions.LogUserOut]({ commit }) {
        await makeRequest(commit, accountApi.logUserOut);
        commit(AccountMutations.SetSession, null);
        localStorageService.remove(SESSION_KEY);
    },
    async [AccountActions.RequestPasswordReset](
        { commit },
        payload: { email: string }
    ) {
        await makeRequest(commit, () =>
            accountApi.requestPasswordReset(payload.email)
        );
    },
    async [AccountActions.ResetPassword](
        { commit },
        payload: { token: string; newPassword: string }
    ) {
        await makeRequest(commit, () =>
            accountApi.resetPassword(payload.token, payload.newPassword)
        );
    },
    async [AccountActions.ActivateAccount](
        { commit },
        payload: { token: string; password: string }
    ) {
        return await makeRequest(commit, () =>
            accountApi.activateAccount(payload.token, payload.password)
        );
    },
    async [AccountActions.GetTwoFactorResetToken](
        { commit },
        payload: { userName: string; recoveryCode: string }
    ) {
        return await makeRequest(commit, () =>
            accountApi.getTwoFactorResetToken(
                payload.userName,
                payload.recoveryCode
            )
        );
    },
    async [AccountActions.GetTwoFactorSetupCode]({ commit }, payload: string) {
        return await makeRequest(commit, () =>
            accountApi.getTwoFactorSetupCode(payload)
        );
    },
    async [AccountActions.ActivateTwoFactorAuthentication](
        { commit },
        payload: {
            token: string;
            code: string;
        }
    ) {
        await makeRequest(commit, () =>
            accountApi.activateTwoFactorAuthentication(
                payload.token,
                payload.code
            )
        );
    }
};

export default actions;
