
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import AuthCard from "@/components/AuthCard";
import BaseDialog from "@/components/BaseDialog";
import { accountModule } from "@/store/modules/account";
import { AccountActions } from "@/store/modules/account/actions";
import { AccountGetters } from "@/store/modules/account/getters";
import Errors from "./BackendResponseErrors.json";
import GenericPasswordChangeForm from "@/components/GenericPasswordChangeForm";
import { Routes } from "@/router/routes";
import { notificationsModule } from "@/store/modules/notifications";
import { NotificationsActions } from "@/store/modules/notifications/actions";

@Component({
    components: { AuthCard, GenericPasswordChangeForm, BaseDialog },
    computed: {
        ...accountModule.mapGetters({
            loading: AccountGetters.Loading,
            errors: AccountGetters.Errors
        })
    },
    methods: {
        ...accountModule.mapActions({
            activateAccount: AccountActions.ActivateAccount
        }),
        ...notificationsModule.mapActions({
            showError: NotificationsActions.NotifyError
        })
    }
})
export default class PasswordReset extends Vue {
    readonly loading!: boolean;
    readonly errors!: string | null;
    private readonly showError!: (error: string | string[]) => void;
    private readonly activateAccount!: (payload: {
        token: string;
        password: string;
    }) => Promise<string | null>;

    informationDialog = false;

    get token(): string {
        return this.$route.query.token as string;
    }

    async submit(password: string): Promise<void> {
        if (!this.token) {
            this.showError("Missing token.");
            return;
        }

        const twoFactorToken = await this.activateAccount({
            token: this.token as string,
            password
        });

        if (twoFactorToken) {
            this.$router.push(
                Routes.TwoFactorActivation + "?token=" + twoFactorToken
            );
        } else {
            this.informationDialog = true;
        }
    }

    goToLogin(): void {
        this.$router.push(Routes.Login);
    }

    @Watch("errors")
    onErrors(errors: string[] | null): void {
        if (!errors) return;

        if (errors[0].includes(Errors.InvalidToken.Error)) {
            this.showError(Errors.InvalidToken.Message);
        } else if (errors[0].includes(Errors.NetworkError.Error)) {
            this.showError(Errors.NetworkError.Message);
        } else if (errors[0].includes(Errors.BadRequest.Error)) {
            this.showError(Errors.BadRequest.Message);
        } else {
            this.showError(Errors.Default.Message);
        }
    }
}
