import monthlyReportApi from "@/api/monthlyReportApi";
import { MonthlyReport } from "@/models/reports/MonthlyReport";
import { makeStoreRequest } from "@/services/requestUtils";
import { RootState } from "@/store";
import { AxiosResponse } from "axios";
import { ActionTree, Commit } from "vuex";
import { MonthlyReportState } from ".";
import { MonthlyReportMutation } from "./mutations";

export enum MonthlyReportActions {
    LoadMonthlyReport = "loadMonthlyReport",
    UpdateMonthlyReport = "updateMonthlyReport"
}

async function makeModuleRequest<T>(
    commit: Commit,
    callback: () => Promise<AxiosResponse<T>>
) {
    return makeStoreRequest(commit, callback, MonthlyReportMutation.SetLoading);
}

const actions: ActionTree<MonthlyReportState, RootState> = {
    async [MonthlyReportActions.LoadMonthlyReport]({ commit }) {
        const monthlyReport = await makeModuleRequest(commit, () =>
            monthlyReportApi.getMonthlyReport()
        );
        commit(MonthlyReportMutation.SetMonthlyReport, monthlyReport);
    },
    async [MonthlyReportActions.UpdateMonthlyReport](
        { commit },
        payload: MonthlyReport
    ) {
        await makeModuleRequest(commit, () =>
            monthlyReportApi.setMonthlyReport(payload)
        );
        commit(MonthlyReportMutation.SetMonthlyReport, payload);
    }
};

export default actions;
