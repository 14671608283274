import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { RuleState } from ".";

export enum RuleGetters {
    IsLoading = "isLoading",
    Errors = "errors",
    Info = "info",
    Rule = "rule",
    Rules = "rules"
}

const getters: GetterTree<RuleState, RootState> = {
    [RuleGetters.IsLoading](state) {
        return state.isLoading;
    },
    [RuleGetters.Errors](state) {
        return state.errors;
    },
    [RuleGetters.Info](state) {
        return state.info;
    },
    [RuleGetters.Rule](state) {
        return state.rule;
    },
    [RuleGetters.Rules](state) {
        return state.rules;
    }
};

export default getters;
