
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";

import AuthCard from "@/components/AuthCard";
import CredentialsForm from "@/components/CredentialsForm";
import TwoFactorForm from "@/components/TwoFactorForm";
import { notificationsModule } from "@/store/modules/notifications";
import { NotificationsActions } from "@/store/modules/notifications/actions";
import { accountModule } from "@/store/modules/account";
import { AccountActions } from "@/store/modules/account/actions";
import { AccountMutations } from "@/store/modules/account/mutations";
import { AccountGetters } from "@/store/modules/account/getters";
import { Routes } from "@/router/routes";
import { LoginResult } from "@/models/LoginResult";
import Errors from "./BackendResponseErrors.json";

@Component({
    components: { AuthCard, CredentialsForm, TwoFactorForm },
    methods: {
        ...accountModule.mapActions({
            logUserIn: AccountActions.LogUserIn,
            logUserInTwoFactor: AccountActions.LogUserInTwoFactor,
            getTwoFactorResetToken: AccountActions.GetTwoFactorResetToken
        }),
        ...accountModule.mapMutations({
            setErrors: AccountMutations.SetErrors
        }),
        ...notificationsModule.mapActions({
            showError: NotificationsActions.NotifyError
        })
    },
    computed: {
        ...accountModule.mapGetters({
            loginResult: AccountGetters.LoginResult,
            loading: AccountGetters.Loading,
            errors: AccountGetters.Errors
        })
    }
})
export default class Login extends Vue {
    protected readonly loading!: boolean;
    protected readonly loginResult!: LoginResult | null;
    protected readonly errors!: string[] | null;

    private readonly logUserIn!: (payload: {
        username: string;
        password: string;
    }) => Promise<void>;
    private readonly logUserInTwoFactor!: (payload: {
        twoFactorPassword: string;
    }) => Promise<void>;
    private readonly getTwoFactorResetToken!: (payload: {
        userName: string;
        recoveryCode: string;
    }) => Promise<string | null>;
    private readonly showError!: (error: string | string[]) => void;

    protected twoFactorEmail = "";

    protected async submitLogin(
        email: string,
        password: string
    ): Promise<void> {
        await this.logUserIn({
            username: email,
            password
        });

        if (this.loginResult?.isMultiFactorRequired) {
            this.twoFactorEmail = email;
            return;
        }

        this.$router.push(
            (this.$route.query.redirecturl as string) ?? Routes.Home
        );
    }

    protected async submitTwoFactor(twoFactorPassword: string): Promise<void> {
        // A standard OTP has a length of 6. The form checks if it's 6 or 8 characters.
        if (twoFactorPassword.length == 6) {
            await this.twoFactorLoginAsync(twoFactorPassword);
        } else {
            // If it is not 6 characters long, it has to be 8 and is an emergency code.
            await this.twoFactorResetAsync(twoFactorPassword);
        }
    }

    private async twoFactorLoginAsync(
        twoFactorPassword: string
    ): Promise<void> {
        await this.logUserInTwoFactor({
            twoFactorPassword
        });

        this.$router.push(
            (this.$route.query.redirecturl as string) ?? Routes.Home
        );
    }

    private async twoFactorResetAsync(
        twoFactorPassword: string
    ): Promise<void> {
        const token = await this.getTwoFactorResetToken({
            userName: this.twoFactorEmail,
            recoveryCode: twoFactorPassword
        });

        this.$router.push(Routes.TwoFactorActivation + "?token=" + token);
    }

    @Watch("errors")
    private onErrors(errors: string[] | null): void {
        if (!errors) return;

        if (errors[0].includes(Errors.BadRequest.Error)) {
            this.showError(Errors.BadRequest.Message);
        } else {
            this.showError(errors[1] ?? Errors.Default.Message);
        }
    }
}
