
import AppHeader from "@/components/AppHeader";
import SideMenu from "@/components/SideMenu";
import AppFooter from "@/components/AppFooter";
import Vue from "vue";

export default Vue.extend({
    components: {
        AppHeader,
        SideMenu,
        AppFooter
    }
});
