import caseApi from "@/api/caseApi";
import { CaseEventEditDetails } from "@/models/cases/CaseEventEditDetails";
import { makeStoreRequest } from "@/services/requestUtils";
import { RootState } from "@/store";
import { ActionContext, ActionTree } from "vuex";
import { CaseState } from ".";
import { CaseMutation } from "./mutations";
import { AxiosError, AxiosResponse } from "axios";
import LockedResult from "@/models/LockedResult";
import { ForwardCaseData } from "@/models/cases/ForwardCaseData";
import { ReplyEventData } from "@/models/cases/ReplyEventData";
import { SendEmailData } from "@/models/cases/SendEmailData";
import CaseUpdate from "@/models/cases/CaseUpdate";

export enum CaseActions {
    Load = "loadCaseById",
    Reload = "reload",
    Update = "updateCase",
    AddComment = "addNewComment",
    UpdateComment = "updateCaseEvent",
    DeleteEvent = "deleteCaseEvent",
    LoadRules = "loadCaseRules",
    StopReminder = "stopReminder",
    UpdateParent = "updateParent",
    DeleteParent = "deleteParent",
    ConvertToNewCase = "convertToNewCase",
    Duplicate = "duplicate",
    Forward = "forward",
    CheckIsReminderProcessAvailable = "checkIsReminderProcessAvailable",
    GetForwardEmailPreviewForCase = "getForwardEmailPreviewForCase",
    Integrate = "integrate",
    ReplyEvent = "replyEvent",
    SendEmail = "sendEmail",
    MarkAsSpam = "markAsSpam",
    Lock = "lock",
    Unlock = "unlock",
    DeleteAttachment = "deleteAttachment",
    CloseRelated = "closeRelated",
    GetReplyTemplate = "getReplyTemplate"
}

async function makeModuleRequest<T>(
    context: ActionContext<CaseState, RootState>,
    callback: () => Promise<AxiosResponse<T>>
): Promise<T> {
    try {
        return await makeStoreRequest(
            context.commit,
            callback,
            CaseMutation.SetLoading
        );
    } catch (error) {
        const axiosError = error as AxiosError;
        if (!axiosError.isAxiosError) throw error;

        const lockedResult = axiosError.response?.data as
            | LockedResult
            | undefined;

        if (lockedResult && lockedResult.isLocked) {
            context.dispatch(CaseActions.Reload);
        }

        throw error;
    }
}

const actions: ActionTree<CaseState, RootState> = {
    async [CaseActions.Load](context, payload: number) {
        const caseModel = await makeModuleRequest(context, () =>
            caseApi.getCaseById(payload)
        );
        context.commit(CaseMutation.SetCase, caseModel);
    },
    async [CaseActions.Reload](context) {
        return await context.dispatch(
            CaseActions.Load,
            context.state.case.caseId
        );
    },
    async [CaseActions.Update](context, payload: CaseUpdate) {
        const caseModel = await makeModuleRequest(context, () =>
            caseApi.updateCase(context.state.case.caseId, payload)
        );
        context.commit(CaseMutation.SetCase, caseModel);
    },
    async [CaseActions.AddComment](context, payload: CaseEventEditDetails) {
        const event = await makeModuleRequest(context, () =>
            caseApi.addNewComment(context.state.case.caseId, payload)
        );
        context.commit(CaseMutation.AddEvent, event);
    },
    async [CaseActions.UpdateComment](
        context,
        {
            caseEventId,
            caseEventEditDetails
        }: { caseEventId: number; caseEventEditDetails: CaseEventEditDetails }
    ) {
        const event = await makeModuleRequest(context, () =>
            caseApi.updateCaseEvent(
                context.state.case.caseId,
                caseEventId,
                caseEventEditDetails
            )
        );
        context.commit(CaseMutation.UpdateEvent, event);
    },
    async [CaseActions.DeleteEvent](context, payload: number) {
        await makeModuleRequest(context, () =>
            caseApi.deleteCaseEvent(context.state.case.caseId, payload)
        );
        context.commit(CaseMutation.DeleteEvent, payload);
    },
    async [CaseActions.ConvertToNewCase](context, payload: number) {
        context.commit(CaseMutation.DeleteEvent, payload);
        return await makeModuleRequest(context, () =>
            caseApi.convertToNewCaseEvent(context.state.case.caseId, payload)
        );
    },
    async [CaseActions.LoadRules](context) {
        const rules = await makeModuleRequest(context, () =>
            caseApi.loadRulesForCase(context.state.case.caseId)
        );
        context.commit(CaseMutation.SetRules, rules);
    },
    async [CaseActions.StopReminder](context) {
        const updatedCase = await makeModuleRequest(context, () =>
            caseApi.stopReminder(context.state.case.caseId)
        );
        context.commit(CaseMutation.SetCase, updatedCase);
    },
    async [CaseActions.UpdateParent](context, payload: number) {
        const updatedCase = await makeModuleRequest(context, () =>
            caseApi.updateParent(context.state.case.caseId, payload)
        );
        context.commit(CaseMutation.SetCase, updatedCase);
    },
    async [CaseActions.DeleteParent](context) {
        await makeModuleRequest(context, () =>
            caseApi.deleteParent(context.state.case.caseId)
        );
        context.commit(CaseMutation.RemoveParent);
    },
    async [CaseActions.Duplicate](context) {
        const updatedCase = await makeModuleRequest(context, () =>
            caseApi.duplicate(context.state.case.caseId)
        );
        context.commit(CaseMutation.SetCase, updatedCase);
    },
    async [CaseActions.Forward](context, payload: ForwardCaseData) {
        const updatedCase = await makeModuleRequest(context, () =>
            caseApi.forwardCase(context.state.case.caseId, payload)
        );
        context.commit(CaseMutation.SetCase, updatedCase);
    },
    async [CaseActions.CheckIsReminderProcessAvailable](context) {
        return await makeModuleRequest(context, () =>
            caseApi.isReminderProcessAvailable(context.state.case.caseId)
        );
    },
    async [CaseActions.GetForwardEmailPreviewForCase](
        context,
        payload: ForwardCaseData
    ) {
        return await makeModuleRequest(context, () =>
            caseApi.getForwardEmailPreviewForCase(
                context.state.case.caseId,
                payload
            )
        );
    },
    async [CaseActions.Integrate](context, targetInternalId: number) {
        const createdCase = await makeModuleRequest(context, () =>
            caseApi.integrate(context.state.case.caseId, targetInternalId)
        );
        context.commit(CaseMutation.SetCase, createdCase);
    },
    async [CaseActions.ReplyEvent](context, payload: ReplyEventData) {
        const updatedCase = await makeModuleRequest(context, () =>
            caseApi.replyCaseEvent(context.state.case.caseId, payload)
        );
        context.commit(CaseMutation.SetCase, updatedCase);
    },
    async [CaseActions.SendEmail](context, payload: SendEmailData) {
        const updatedCase = await makeModuleRequest(context, () =>
            caseApi.sendEmail(context.state.case.caseId, payload)
        );
        context.commit(CaseMutation.SetCase, updatedCase);
    },
    async [CaseActions.MarkAsSpam](context) {
        const updatedCase = await makeModuleRequest(context, () =>
            caseApi.markAsSpam(context.state.case.caseId)
        );
        context.commit(CaseMutation.SetCase, updatedCase);
    },
    async [CaseActions.Lock](context) {
        const lockedResult = await makeModuleRequest(context, () =>
            caseApi.lock(context.state.case.caseId)
        );
        if (lockedResult.isLocked)
            context.commit(
                CaseMutation.SetLockedByUser,
                lockedResult.lockedByUser
            );
    },
    async [CaseActions.Unlock](context) {
        await makeModuleRequest(context, () =>
            caseApi.unlock(context.state.case.caseId)
        );
        context.commit(CaseMutation.SetLockedByUser, undefined);
    },
    async [CaseActions.DeleteAttachment](context, payload: number) {
        await makeModuleRequest(context, () =>
            caseApi.deleteAttachment(payload)
        );
        context.commit(CaseMutation.DeleteAttachment, payload);
    },
    async [CaseActions.CloseRelated](context) {
        const updatedCase = await makeModuleRequest(context, () =>
            caseApi.closeRelated(context.state.case.caseId)
        );
        context.commit(CaseMutation.SetCase, updatedCase);
    },
    async [CaseActions.GetReplyTemplate](context) {
        const template = await makeModuleRequest(context, () =>
            caseApi.getReplyTemplate()
        );
        context.commit(CaseMutation.SetReplyTemplate, template);
    }
};

export default actions;
