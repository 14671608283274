import { AxiosResponse } from "axios";
import api from ".";
import { AccountInfo } from "../models/billingAccount/AccountInfo";

function getBillingSummary(
    year: number,
    month: number
): Promise<AxiosResponse<AccountInfo>> {
    return api.get("billing/summary", { params: { year, month } });
}

function createBillings(
    year: number,
    month: number
): Promise<AxiosResponse<void>> {
    return api.post("billing", { year, month });
}

export default {
    getBillingSummary,
    createBillings
};
