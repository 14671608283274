import { ModuleTree } from "vuex";
import { RootState } from "..";
import user from "./user";
import account from "./account";
import role from "./role";
import market from "./market";
import caseModule from "./case";
import contact from "./contact";
import codeLevel from "./codeLevel";
import rule from "./rule";
import postQualification from "./postQualification";
import notifications from "./notifications";
import billingAccount from "./billingAccount";
import billing from "./billing";
import blacklist from "./blacklist";
import solution from "./solution";
import scroll from "./scroll";
import cases from "./cases";
import route from "./route";
import monthlyReport from "./monthlyReport";

const modules: ModuleTree<RootState> = {
    user,
    account,
    role,
    market,
    contact,
    case: caseModule,
    cases,
    codeLevel,
    rule,
    postQualification,
    notifications,
    billingAccount,
    billing,
    blacklist,
    solution,
    scroll,
    route,
    monthlyReport
};

export default modules;
