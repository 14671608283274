
import Vue from "vue";
import Component from "vue-class-component";
import ContactPostQualificationForm from "@/components/ContactPostQualificationForm";
import { PostQualification } from "@/models/postQualification/PostQualification";
import postQualificationApi from "@/api/postQualificationApi";
import { Routes } from "@/router/routes";
import { postQualificationModule } from "@/store/modules/postQualification";
import { PostQualificationMutation } from "@/store/modules/postQualification/mutations";
import { makeRequest } from "@/services/requestUtils";
import { AxiosError } from "axios";

@Component({
    components: { ContactPostQualificationForm },
    methods: {
        ...postQualificationModule.mapMutations({
            setToken: PostQualificationMutation.SetToken
        })
    }
})
export default class ContactPostQualification extends Vue {
    private readonly setToken!: (token: string | null) => void;

    postQualification = {} as PostQualification;
    isDataLoading = false;
    isSubmitting = false;

    get token(): string {
        return this.$route.params["token"];
    }

    async created(): Promise<void> {
        if (!this.token) {
            this.handleNotFound();
            return;
        }

        try {
            const request = async () =>
                await postQualificationApi.readPostQualification(this.token);
            this.postQualification = await makeRequest(
                request,
                (loading) => (this.isDataLoading = loading)
            );
        } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response?.status === 404) this.handleNotFound();
        }
    }

    async submit(): Promise<void> {
        try {
            const request = async () =>
                await postQualificationApi.updatePostQualificationContact(
                    this.token,
                    this.postQualification
                );
            await makeRequest(
                request,
                (loading) => (this.isSubmitting = loading)
            );
        } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response?.status === 404) this.handleNotFound();
        }

        this.setToken(this.token);
        this.$router.push(Routes.PostQualificationThanks);
    }

    handleNotFound(): void {
        this.$router.push(Routes.PostQualificationNotFound);
    }
}
