import { AxiosResponse } from "axios";
import { ValidationReponseData } from "@/models/ValidationResponseData";

export function getErrorTextFromResponse(response: AxiosResponse): string {
    if (!response.data) return response.statusText;

    const validationData = response.data as ValidationReponseData;

    if (validationData.errors !== undefined) {
        return Object.entries(validationData.errors)
            .map((error) => {
                return error[1];
            })
            .join(" ");
    }

    return response.data;
}
