
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { Blacklist } from "@/models/blacklist/Blacklist";

@Component({})
export default class BlacklistEditor extends Vue {
    @Prop({ type: Object, required: true })
    public readonly blacklistModel!: Blacklist;
}
