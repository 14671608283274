
import { Component, Prop, PropSync } from "vue-property-decorator";
import BlacklistEditor from "@/components/BlacklistEditor";
import { blacklistModule } from "@/store/modules/blacklist";
import { BlacklistActions } from "@/store/modules/blacklist/actions";
import Vue from "vue";
import { Blacklist } from "@/models/blacklist/Blacklist";
import { BlacklistGetters } from "@/store/modules/blacklist/getters";
import { BlacklistMutation } from "@/store/modules/blacklist/mutations";
import { VuetifyForm } from "@/models/VuetifyForm";

@Component({
    components: { BlacklistEditor },

    methods: {
        ...blacklistModule.mapActions({
            createBlacklist: BlacklistActions.CreateBlacklist,
            getBlacklistById: BlacklistActions.LoadBlacklistById,
            updateBlacklist: BlacklistActions.UpdateBlacklist
        }),
        ...blacklistModule.mapMutations({
            setBlacklist: BlacklistMutation.SetBlacklist
        })
    },
    computed: {
        ...blacklistModule.mapGetters({
            errors: BlacklistGetters.Errors,
            loading: BlacklistGetters.IsLoading,
            blacklistModel: BlacklistGetters.Blacklist
        })
    }
})
export default class BlacklistUpsetDialog extends Vue {
    @Prop({ type: Number, required: false, default: 0 })
    public readonly blacklistEntryId!: number;
    @PropSync("visible", { type: Boolean, required: true })
    public isVisible!: boolean;
    $refs!: {
        form: VuetifyForm;
    };
    protected currentBlacklistModel: Blacklist = {
        blacklistId: 0,
        email: "",
        firstName: null,
        lastName: null,
        createdDateTime: "",
        lastModifiedDateTime: ""
    };
    protected readonly loading!: boolean;
    private readonly createBlacklist!: (
        blacklist: Blacklist
    ) => Promise<number>;
    private readonly getBlacklistById!: (
        blacklistId: number
    ) => Promise<Blacklist>;
    private readonly updateBlacklist!: (
        blacklist: Blacklist
    ) => Promise<Blacklist>;
    private readonly setBlacklist!: (blacklist: Blacklist | null) => void;
    private readonly errors!: string[] | null;
    private blacklistModel!: Blacklist | null;

    mounted(): void {
        if (this.blacklistEntryId !== 0) {
            this.getBlacklistById(this.blacklistEntryId).then(() => {
                if (this.blacklistModel) {
                    this.currentBlacklistModel = { ...this.blacklistModel };
                }
            });
        }
    }

    submit(): void {
        const result = this.$refs.form.validate();

        if (!result) return;

        if (this.blacklistEntryId === 0) {
            this.createBlacklist(this.currentBlacklistModel).then(
                () => (this.isVisible = false)
            );
        } else {
            this.updateBlacklist(this.currentBlacklistModel).then(() => {
                this.isVisible = false;
                this.setBlacklist(this.currentBlacklistModel);
            });
        }
    }

    cancel(): void {
        this.setBlacklist(null);
        this.currentBlacklistModel = {
            blacklistId: 0,
            email: "",
            firstName: null,
            lastName: null,
            createdDateTime: "",
            lastModifiedDateTime: ""
        };
        this.isVisible = false;
        this.$emit("cancel");
    }
}
