import contactApi from "@/api/contactApi";
import { Contact } from "@/models/cases/Contact";
import { makeStoreRequest } from "@/services/requestUtils";
import { RootState } from "@/store";
import { ActionTree, Commit } from "vuex";
import { ContactState } from ".";
import { ContactMutation } from "./mutations";
import { PaginationInfo } from "@/models/PaginationInfo";
import { ContactFilter } from "@/models/Gdpr/ContactFilter";
import { ContactCar } from "@/models/cases/ContactCar";
import { AxiosResponse } from "axios";

export enum ContactActions {
    SearchContacts = "loadContacts",
    LoadContactById = "loadContactById",
    CreateContact = "createContact",
    UpdateContact = "updateContact",
    LoadContactsWithPagination = "loadContactsWithPagination",
    DeleteContact = "deleteContact",
    CreateContactCar = "createContactCar",
    UpdateContactCar = "updateContactCar",
    DeleteContactCar = "deleteContactCar"
}

async function makeModuleRequest<T>(
    commit: Commit,
    callback: () => Promise<AxiosResponse<T>>
) {
    return makeStoreRequest(commit, callback, ContactMutation.SetLoading);
}

const actions: ActionTree<ContactState, RootState> = {
    async [ContactActions.SearchContacts](
        { commit },
        payload: { firstName: string; lastName: string; email: string }
    ) {
        const contacts = await makeModuleRequest(commit, () =>
            contactApi.searchContact(
                payload.firstName,
                payload.lastName,
                payload.email
            )
        );
        commit(ContactMutation.SetContacts, contacts);
    },
    async [ContactActions.LoadContactById]({ commit }, payload: number) {
        const contact = await makeModuleRequest(commit, () =>
            contactApi.getContact(payload)
        );
        commit(ContactMutation.SetContact, contact);
    },
    async [ContactActions.CreateContact]({ commit }, payload: Contact) {
        const contactId = await makeModuleRequest(commit, () =>
            contactApi.createContact(payload)
        );
        commit(ContactMutation.SetContact, { ...payload, contactId });
    },
    async [ContactActions.UpdateContact]({ commit }, payload: Contact) {
        await makeModuleRequest(commit, () =>
            contactApi.updateContact(payload)
        );
        commit(ContactMutation.SetContact, payload);
    },
    async [ContactActions.LoadContactsWithPagination](
        { commit },
        payload: PaginationInfo & ContactFilter
    ) {
        const paginationResult = await makeModuleRequest(commit, () =>
            contactApi.getContactsWithPagination(payload)
        );
        commit(ContactMutation.SetPaginationResult, paginationResult);
    },
    async [ContactActions.DeleteContact]({ commit }, payload: number) {
        await makeModuleRequest(commit, () =>
            contactApi.deleteContactById(payload)
        );
        commit(ContactMutation.RemoveContact, payload);
    },
    async [ContactActions.CreateContactCar](
        { commit },
        payload: { contactId: number; contactCar: ContactCar }
    ) {
        const contactCarId = await makeModuleRequest(commit, () =>
            contactApi.createContactCar(payload.contactId, payload.contactCar)
        );
        commit(ContactMutation.AddContactCar, {
            car: payload.contactCar,
            contactCarId: contactCarId
        });
    },
    async [ContactActions.UpdateContactCar](
        { commit },
        payload: { contactId: number; contactCar: ContactCar }
    ) {
        await makeModuleRequest(commit, () =>
            contactApi.updateContactCar(payload.contactId, payload.contactCar)
        );
        commit(ContactMutation.SetContactCar, payload);
    },
    async [ContactActions.DeleteContactCar](
        { commit },
        payload: { contactId: number; contactCarId: number }
    ) {
        await makeModuleRequest(commit, () =>
            contactApi.deleteContactCar(payload.contactId, payload.contactCarId)
        );
        commit(ContactMutation.RemoveContactCar, payload);
    }
};

export default actions;
