import { PaginationResult } from "@/models/PaginationResult";
import { Rule } from "@/models/rules/Rule";
import { RuleInfo } from "@/models/rules/RuleInfo";
import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export interface RuleState {
    rule: Rule | null;
    rules: PaginationResult<Rule>;
    info: RuleInfo | null;
    isLoading: boolean;
    errors?: string[] | null;
}

const state: RuleState = {
    rule: null,
    rules: {
        totalCount: 0,
        items: []
    },
    info: null,
    isLoading: false,
    errors: []
};

const rule: Module<RuleState, RootState> = {
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions,
    namespaced: true
};

export const ruleModule = createNamespacedHelpers("rule");

export default rule;
