
import { Case } from "@/models/cases/Case";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import DatePicker from "@/components/DatePicker";
import { ComplexSolveDTO } from "@/models/solution/ComplexSolveDTO";
import BaseButton from "@/components/BaseButton";
import { solutionModule } from "@/store/modules/solution";
import { SolutionGetters } from "@/store/modules/solution/getters";
import { VuetifyForm } from "@/models/VuetifyForm";
import * as caseSources from "@/consts/caseSources";
import CaseSolutionInput from "./CaseSolutionInput";
import solutionValidationService from "@/services/solutionValidationService";
import NotificationDialog from "@/components/NotificationDialog";
import validationRules from "@/services/validationRules";

@Component({
    components: {
        DatePicker,
        BaseButton,
        CaseSolutionInput,
        NotificationDialog
    },
    computed: {
        ...solutionModule.mapGetters({
            loading: SolutionGetters.Loading
        })
    }
})
export default class ComplexCaseSolution extends Vue {
    protected readonly loading!: boolean;

    @Prop({ type: Object, required: true })
    public readonly caseModel!: Case;

    $refs!: {
        form: VuetifyForm;
    };

    protected readonly solveData: Partial<ComplexSolveDTO> = {};
    protected validationErrorVisible = false;
    protected datePickerError = false;
    protected vinRules = [validationRules.required("VIN")];

    created(): void {
        const codeLevelSets = [...this.caseModel.codeLevelSets];
        this.solveData.solutions = codeLevelSets
            .sort((cs) => cs.order)
            .map((cs) => ({
                caseId: this.caseModel.caseId,
                caseCodeLevelSetId: cs.caseCodeLevelSetId
            }));
    }

    get isBoardCase(): boolean {
        return (
            this.caseModel?.source?.name?.toUpperCase() ===
            caseSources.BOARD.toUpperCase()
        );
    }

    validateAdditionalFields(): boolean {
        if (this.isBoardCase) {
            this.datePickerError = !this.solveData.finalReplyDate;
            return !this.datePickerError;
        }
        return true;
    }

    submit(): void {
        let result = this.validateAdditionalFields();
        result &&= this.$refs.form.validate();
        result &&= this.solveData.solutions !== undefined;
        this.solveData.solutions?.forEach((solution) => {
            result &&= solutionValidationService.validate(solution);
        });
        if (result) {
            this.$emit("solve", this.solveData);
        } else {
            this.validationErrorVisible = true;
        }
    }
}
