import { ActionTree } from "vuex";
import { ScrollState } from "./index";
import { RootState } from "@/store";
import { ScrollMutation } from "./mutations";

export enum ScrollActions {
    SaveElementScrollPosition = "saveElementScrollPosition",
    SetScrollPositionToElement = "setScrollPositionToElement"
}

const actions: ActionTree<ScrollState, RootState> = {
    [ScrollActions.SaveElementScrollPosition](
        { commit },
        { key, element }: { key: string; element: Element }
    ) {
        commit(ScrollMutation.SetScrollPosition, {
            key,
            number: element.scrollTop
        });
    },

    [ScrollActions.SetScrollPositionToElement](
        { state },
        { key, element }: { key: string; element: Element }
    ) {
        element.scrollTop = state.positions.get(key) ?? 0;
    }
};

export default actions;
