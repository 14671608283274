import solutionApi from "@/api/solutionApi";
import { AddMoreDaysDTO } from "@/models/solution/AddMoreDaysDTO";
import { ComplexSolveDTO } from "@/models/solution/ComplexSolveDTO";
import { SimpleSolveDTO } from "@/models/solution/SimpleSolveDTO";
import { makeStoreRequest } from "@/services/requestUtils";
import { RootState } from "@/store";
import { AxiosResponse } from "axios";
import { ActionTree, Commit } from "vuex";
import { SolutionState } from ".";
import { SolutionMutations } from "./mutations";

export enum SolutionActions {
    GetSolutionCase = "checkCase",
    AddMoreDays = "addMoreDays",
    SimpleSolve = "simpleSolve",
    ComplexSolve = "complexSolve",
    LoadConfig = "loadConfig"
}

async function makeModuleRequest<T>(
    commit: Commit,
    callback: () => Promise<AxiosResponse<T>>
) {
    try {
        return await makeStoreRequest(
            commit,
            callback,
            SolutionMutations.SetLoading
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        commit(SolutionMutations.SetErrors, error.message);
        throw error;
    }
}

const actions: ActionTree<SolutionState, RootState> = {
    async [SolutionActions.GetSolutionCase](
        { commit },
        payload: { token: string; marketStatus: string }
    ) {
        try {
            const caseModel = await makeModuleRequest(commit, () =>
                solutionApi.getSolutionCase(payload.token, payload.marketStatus)
            );
            commit(SolutionMutations.SetCase, caseModel);
        } catch {
            return false;
        }

        return true;
    },
    async [SolutionActions.AddMoreDays]({ commit }, payload: AddMoreDaysDTO) {
        const result = await makeModuleRequest(commit, () =>
            solutionApi.addMoreDays(payload)
        );
        return result;
    },
    async [SolutionActions.SimpleSolve]({ commit }, payload: SimpleSolveDTO) {
        await makeModuleRequest(commit, () => solutionApi.simpleSolve(payload));
    },
    async [SolutionActions.ComplexSolve]({ commit }, payload: ComplexSolveDTO) {
        const attachment = payload.file;
        delete payload.file;

        const caseEventId = await makeModuleRequest(commit, () =>
            solutionApi.complexSolve(payload)
        );

        if (attachment) {
            await makeModuleRequest(commit, () =>
                solutionApi.uploadAttachmentToComplexSolve(
                    payload.token,
                    caseEventId,
                    attachment
                )
            );
        }
    }
};

export default actions;
