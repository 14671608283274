import billingAccountApi from "@/api/billingAccountApi";
import { makeStoreRequest } from "@/services/requestUtils";
import { RootState } from "@/store";
import { AxiosResponse } from "axios";
import { ActionTree, Commit } from "vuex";
import { BillingAccountState } from ".";
import { BillingAccountMutation } from "./mutations";

export enum BillingAccountActions {
    LoadAccountInfo = "loadAccountInfo"
}

async function makeModuleRequest<T>(
    commit: Commit,
    callback: () => Promise<AxiosResponse<T>>
) {
    return makeStoreRequest(
        commit,
        callback,
        BillingAccountMutation.SetLoading
    );
}

const actions: ActionTree<BillingAccountState, RootState> = {
    async [BillingAccountActions.LoadAccountInfo]({ commit, state }) {
        const request = async () => {
            return await billingAccountApi.getBillingSummary(
                state.year,
                state.month
            );
        };

        const accountInfo = await makeModuleRequest(commit, request);
        commit(BillingAccountMutation.SetAccountInfo, accountInfo);
    }
};

export default actions;
