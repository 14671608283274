import { Case } from "@/models/cases/Case";
import { CaseEvent } from "@/models/cases/CaseEvent";
import { Rule } from "@/models/rules/Rule";
import Vue from "vue";
import { MutationTree } from "vuex";
import { CaseState } from ".";
import LockedByUser from "@/models/users/LockedByUser";

export enum CaseMutation {
    SetCase = "setCase",
    SetLoading = "setLoading",
    AddEvent = "addNewEvent",
    UpdateEvent = "updateCaseEvent",
    DeleteEvent = "deleteCaseEvent",
    SetRules = "setCaseRules",
    SetLockedByUser = "setCaseLockedByUser",
    RemoveParent = "removeParent",
    DeleteAttachment = "deleteAttachment",
    SetReplyTemplate = "setReplyTemplate"
}

const mutations: MutationTree<CaseState> = {
    [CaseMutation.SetCase](state, payload: Case) {
        state.case = payload;
    },
    [CaseMutation.SetLoading](state, payload: boolean) {
        state.loading = payload;
    },
    [CaseMutation.AddEvent](state, caseEvent: CaseEvent) {
        state.case.caseEvents.unshift(caseEvent);
    },
    [CaseMutation.UpdateEvent](state, payload: CaseEvent) {
        const index = state.case.caseEvents.findIndex(
            (c) => c.caseEventId === payload.caseEventId
        );
        if (index >= 0) {
            Vue.set(state.case.caseEvents, index, payload);
        }
    },
    [CaseMutation.DeleteEvent](state, payload: number) {
        state.case.caseEvents = state.case.caseEvents.filter(
            (c) => c.caseEventId !== payload
        );
    },
    [CaseMutation.SetRules](state, payload: Rule[]) {
        state.rules = payload;
    },
    [CaseMutation.SetLockedByUser](state, payload: LockedByUser | null) {
        state.case.lockedByUser = payload;
    },
    [CaseMutation.RemoveParent](state) {
        state.case.parentId = null;
        state.case.parent = null;
    },
    [CaseMutation.DeleteAttachment](state, payload: number) {
        for (const event of state.case.caseEvents) {
            if (event.attachments)
                event.attachments = event.attachments.filter(
                    (a) => a.attachmentId !== payload
                );
        }
    },
    [CaseMutation.SetReplyTemplate](state, payload: string) {
        state.replyTemplate = payload;
    }
};

export default mutations;
