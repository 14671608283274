import { MutationTree } from "vuex";
import { PaginationResult } from "@/models/PaginationResult";
import { Blacklist } from "@/models/blacklist/Blacklist";
import { BlacklistState } from "@/store/modules/blacklist/index";

export enum BlacklistMutation {
    SetBlacklist = "setBlacklist",
    SetLoading = "setLoading",
    SetErrors = "setErrors",
    SetPaginationResult = "setPaginationResult",
    RemoveBlacklist = "removeBlacklist",
    AddBlacklist = "addBlacklist",
    UpdateBlackList = "updateBlacklist"
}

const mutations: MutationTree<BlacklistState> = {
    [BlacklistMutation.SetPaginationResult](
        state,
        payload: PaginationResult<Blacklist>
    ) {
        state.paginationData = payload;
    },
    [BlacklistMutation.AddBlacklist](state, payload: Blacklist) {
        state.paginationData.items.push(payload);
        state.paginationData.totalCount += 1;
    },
    [BlacklistMutation.UpdateBlackList](state, payload: Blacklist) {
        state.paginationData.items = state.paginationData.items.map((item) => {
            if (item.blacklistId === payload.blacklistId) {
                return payload;
            }
            return item;
        });
    },
    [BlacklistMutation.SetBlacklist](state, payload: Blacklist | null) {
        state.blacklist = payload;
    },
    [BlacklistMutation.SetLoading](state, payload: boolean) {
        state.loading = payload;
    },
    [BlacklistMutation.SetErrors](state, payload: string[]) {
        state.errors = payload;
    },
    [BlacklistMutation.RemoveBlacklist](state, payload: number) {
        if (state.paginationData) {
            state.paginationData.items = state.paginationData.items.filter(
                (c) => c.blacklistId !== payload
            );
            if (state.blacklist?.blacklistId === payload) {
                state.blacklist = null;
            }
            state.paginationData.totalCount -= 1;
        }
    }
};

export default mutations;
