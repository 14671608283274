import api from "@/api/index";
import { Blacklist } from "@/models/blacklist/Blacklist";
import { PaginationInfo } from "@/models/PaginationInfo";
import { PaginationResult } from "@/models/PaginationResult";
import { BlacklistFilter } from "@/models/blacklist/BlacklistFilter";
import { AxiosResponse } from "axios";

function createBlacklist(
    blacklist: Omit<Blacklist, "blacklistId">
): Promise<AxiosResponse<number>> {
    return api.post("blacklist", blacklist);
}

function getBlacklistWithPagination(
    paginationInfo: PaginationInfo,
    blacklistFilter: BlacklistFilter
): Promise<AxiosResponse<PaginationResult<Blacklist>>> {
    return api.post("blacklist/list", blacklistFilter, {
        params: { ...paginationInfo }
    });
}

function getBlacklistById(
    blacklistId: number
): Promise<AxiosResponse<Blacklist>> {
    return api.get(`blacklist/${blacklistId}`);
}

function updateBlacklist(
    blacklist: Blacklist
): Promise<AxiosResponse<Blacklist>> {
    return api.put(`blacklist/${blacklist.blacklistId}`, blacklist);
}

function deleteBlacklistById(
    blacklistId: number
): Promise<AxiosResponse<void>> {
    return api.delete(`blacklist/${blacklistId}`);
}

export default {
    createBlacklist,
    getBlacklistWithPagination,
    getBlacklistById,
    updateBlacklist,
    deleteBlacklistById
};
