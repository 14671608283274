
import Vue from "vue";
import { Component, Prop, PropSync, Emit } from "vue-property-decorator";

@Component
export default class ConfirmationDialog extends Vue {
    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    @Prop({ type: String, default: "Confirmation" })
    public title!: string;

    @Prop({ type: String, default: "Do you confirm operation?" })
    public message!: string;

    @Prop({ type: String, default: "Yes" })
    public confirmButton!: string;

    @Prop({ type: String, default: "No" })
    public cancelButton!: string;

    @Prop({ type: Number, required: false, default: 150 })
    public height!: number;

    @Emit()
    confirm(): void {
        this.popupVisible = false;
    }

    @Emit()
    cancel(): void {
        this.popupVisible = false;
    }
}
