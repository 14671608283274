
import MainLayout from "./layouts/MainLayout";
import AuthLayout from "./layouts/AuthLayout";
import WithFilterLayout from "./layouts/WithFilterLayout";
import ExternalLayout from "./layouts/ExternalLayout";
import NotificationSnackbar from "./components/NotificationSnackbar";
import Vue from "vue";
import Component from "vue-class-component";
import store from "@/store";
import { AccountActions } from "./store/modules/account/actions";
import { notificationsModule } from "./store/modules/notifications";
import { NotificationsActions } from "./store/modules/notifications/actions";
import { AxiosError } from "axios";
import LockedResult from "@/models/LockedResult";

@Component({
    components: {
        MainLayout,
        AuthLayout,
        WithFilterLayout,
        ExternalLayout,
        NotificationSnackbar
    },
    methods: {
        ...notificationsModule.mapActions({
            showError: NotificationsActions.NotifyError
        })
    }
})
export default class App extends Vue {
    readonly showError!: (error: string) => void;

    get layout(): string {
        return this.$route?.meta?.layout ?? "MainLayout";
    }

    mounted(): void {
        try {
            if (
                !this.$route.matched.some(
                    (record) => record.meta.allowAnonymous
                )
            ) {
                store.dispatch("account/" + AccountActions.VerifyLogin);
            }
        } catch (error) {
            this.errorCaptured(error);
        }
    }

    errorCaptured(error: unknown): boolean {
        const axiosError = error as AxiosError;

        if (axiosError.isAxiosError) {
            const lockedResult = axiosError.response?.data as
                | LockedResult
                | undefined;
            if (lockedResult && lockedResult.isLocked) return false;

            this.showError(axiosError.message);
            return false;
        }

        this.showError("Unknown error");
        return true;
    }
}
