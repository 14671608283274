
import { VuetifyForm } from "@/models/VuetifyForm";
import Component from "vue-class-component";
import BaseSolutionComponent from "../BaseSolutionComponent";
import BaseButton from "@/components/BaseButton";
import { solutionModule } from "@/store/modules/solution";
import { SolutionActions } from "@/store/modules/solution/actions";
import { AddMoreDaysDTO } from "@/models/solution/AddMoreDaysDTO";
import dateFormat from "dateformat";

@Component({
    components: { BaseButton },
    methods: {
        ...solutionModule.mapActions({
            addMoreDays: SolutionActions.AddMoreDays
        })
    }
})
export default class AddMoreDays extends BaseSolutionComponent {
    private readonly addMoreDays!: (payload: AddMoreDaysDTO) => Promise<Date>;

    protected days = 0;
    protected reason = "";

    protected nextDate: string | null = null;
    protected disabled = false;
    protected showSkeleton = true;

    $refs!: {
        form: VuetifyForm;
    };

    created(): void {
        if (this.tokenData) {
            this.getSolutionCase(this.tokenData).then((result) => {
                if (!result) {
                    this.disabled = true;
                }

                if (this.daysParam) {
                    this.setMoreDays(this.daysParam);
                } else {
                    this.showSkeleton = false;
                }
            });
        } else {
            this.showError("Invalid Token");
        }
    }

    get daysParam(): number | null {
        const days = this.$route.query.days;
        if (days && !isNaN(Number(days))) {
            return Number(days);
        }
        return null;
    }

    async submit(): Promise<void> {
        const result = this.$refs.form.validate();
        if (result) {
            await this.setMoreDays(this.days, this.reason);
        }
    }

    private async setMoreDays(days: number, reason?: string) {
        if (this.tokenData) {
            const date = await this.addMoreDays({
                token: this.tokenData.token,
                days: days,
                reason: reason
            });
            this.nextDate = dateFormat(date, "dd.mm.yyyy");
        }
    }
}
