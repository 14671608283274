
import { Case } from "@/models/cases/Case";
import { CodeLevelData } from "@/models/CodeLevelData";
import { CaseSolution } from "@/models/solution/CaseSolution";
import { codeLevelModule } from "@/store/modules/codeLevel";
import { CodeLevelActions } from "@/store/modules/codeLevel/actions";
import { CodeLevelGetters } from "@/store/modules/codeLevel/getters";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import CaseSolutionFields from "./CaseSolutionFields";

@Component({
    components: {
        CaseSolutionFields
    },
    methods: {
        ...codeLevelModule.mapActions({
            loadCodeLevels: CodeLevelActions.LoadCodeLevels
        })
    },
    computed: {
        ...codeLevelModule.mapGetters({
            codeLevels: CodeLevelGetters.CodeLevelData,
            isLoading: CodeLevelGetters.IsLoading
        })
    }
})
export default class CaseSolutionInput extends Vue {
    private loadCodeLevels!: () => Promise<void>;

    protected codeLevels!: CodeLevelData;
    protected isLoading!: boolean;

    @Prop({ type: Object, required: true })
    public readonly caseModel!: Case;

    @Prop({ type: Array, required: true })
    public readonly solutions!: CaseSolution[];

    async created(): Promise<void> {
        await this.loadCodeLevels();
    }

    isPrimary(solution: CaseSolution): boolean {
        return (
            this.caseModel.codeLevelSets.find(
                (cs) => cs.caseCodeLevelSetId === solution.caseCodeLevelSetId
            )?.isPrimary ?? false
        );
    }

    getSolutionName(solution: CaseSolution): string | null {
        const codeLevelSet = this.caseModel.codeLevelSets.find(
            (cs) => cs.caseCodeLevelSetId === solution.caseCodeLevelSetId
        );

        if (!codeLevelSet) return null;

        const area = this.codeLevels.codeLevelAreas.find(
            (a) => a.id === codeLevelSet.codeLevelAreaId
        );
        const topicGroup = area?.topicGroups.find(
            (t) => t.id === codeLevelSet.codeLevelTopicGroupId
        );
        const topicDetail = topicGroup?.codeLevelTopicDetails.find(
            (td) => td.id === codeLevelSet.codeLevelTopicDetailId
        );

        return `${area?.name} - ${topicGroup?.name} - ${topicDetail?.name}`;
    }
}
