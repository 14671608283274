import { AxiosResponse } from "axios";
import api from ".";
import { MonthBillingData } from "../models/billing/MonthBillingData";

function getMonthBillingData(
    year: number,
    month: number
): Promise<AxiosResponse<MonthBillingData[]>> {
    return api.get("billing", { params: { year, month } });
}

export default {
    getMonthBillingData
};
