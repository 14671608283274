import { MonthlyReport } from "@/models/reports/MonthlyReport";
import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export interface MonthlyReportState {
    monthlyReport: MonthlyReport | null;
    errors: string[] | null;
    loading: boolean;
}

const monthlyReportState: MonthlyReportState = {
    monthlyReport: null,
    errors: [],
    loading: false
};

const monthlyReport: Module<MonthlyReportState, RootState> = {
    namespaced: true,
    state: monthlyReportState,
    actions: actions,
    getters: getters,
    mutations: mutations
};

export const monthlyReportModule = createNamespacedHelpers("monthlyReport");

export default monthlyReport;
