
import Vue from "vue";
import { Component, Ref } from "vue-property-decorator";
import { VuetifyForm } from "@/models/VuetifyForm";
import FieldData from "@/services/fieldData";

@Component
export default class GenericPasswordChangeForm extends Vue {
    protected readonly passwordRequiredPattern =
        /^(?=.*["!$%&/()=?~{[\]}\\@+\-_#'<>|,;.:])(?=.*[a-zA-Z])(?=.*\d).*$/;

    protected readonly passwordAllowedPattern =
        /^[A-Za-z\d!"$%&/()=?~{[\]}\\@+\-_#'<>|,;.:]*$/;

    protected readonly requiredCharacters =
        "Password must contain at least one number, one letter and one of these special character: " +
        " ! \" $ % & / ( ) = ? ~ { [ ] } \\ @ + - _ # ' < > | , ; . :";

    protected readonly allowedSpecialCharacters =
        "Password may only contain roman letters, numbers and these special characters:" +
        " ! \" $ % & / ( ) = ? ~ { [ ] } \\ @ + - _ # ' < > | , ; . :";

    @Ref() readonly form!: VuetifyForm;

    readonly passwordData = new FieldData("Password", {
        required: true,
        minLength: 6,
        maxLength: 40
    });
    readonly repeatPasswordData = new FieldData("Confirm password", {
        required: true
    });

    readonly passwordRules = [
        ...this.passwordData.rules,
        (p: string): boolean | string =>
            this.passwordRequiredPattern.test(p) || this.requiredCharacters,
        (p: string): boolean | string =>
            this.passwordAllowedPattern.test(p) || this.allowedSpecialCharacters
    ];
    readonly repeatPasswordRules = [
        ...this.repeatPasswordData.rules,
        this.passwordConfirmMatchRule
    ];

    password = "";
    passwordConfirm = "";

    submitForm(): void {
        if (!this.form.validate()) return;

        this.$emit("submit", this.password);
    }

    passwordConfirmMatchRule(p: string): boolean | string {
        return (
            p === this.password || "Password and Confirm Password do not match"
        );
    }
}
