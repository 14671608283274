
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import AppIcon from "../AppIcon";
import { MenuItem } from "../SideMenu/SideMenu.vue";

@Component({
    components: { AppIcon }
})
export default class SideMenuItem extends Vue {
    readonly clickEventString = "click";

    @Prop({ type: Object, required: true })
    protected readonly item!: MenuItem;

    get isActive(): boolean {
        if (!this.item.to) return false;

        const path = this.$route.fullPath;
        return (
            (this.item.exact && path === this.item.to) ||
            (!this.item.exact && path.startsWith(this.item.to))
        );
    }

    async navigate(): Promise<void> {
        if (this.item.to && this.$route.path !== this.item.to) {
            if (this.item.onClick) await this.item.onClick();

            await this.$router.push(this.item.to);
        }
    }
}
