
import Vue from "vue";
import { Component, Ref, Watch } from "vue-property-decorator";
import AuthCard from "@/components/AuthCard";
import { accountModule } from "@/store/modules/account";
import { AccountActions } from "@/store/modules/account/actions";
import BaseDialog from "@/components/BaseDialog";
import FieldData from "@/services/fieldData";
import { Routes } from "@/router/routes";
import { notificationsModule } from "@/store/modules/notifications";
import { NotificationsActions } from "@/store/modules/notifications/actions";
import { AccountGetters } from "@/store/modules/account/getters";
import { VuetifyForm } from "@/models/VuetifyForm";

@Component({
    components: { AuthCard, BaseDialog },
    computed: {
        ...accountModule.mapGetters({
            loading: AccountGetters.Loading,
            errors: AccountGetters.Errors
        })
    },
    methods: {
        ...accountModule.mapActions({
            requestPasswordReset: AccountActions.RequestPasswordReset
        }),
        ...notificationsModule.mapActions({
            showError: NotificationsActions.NotifyError
        })
    }
})
export default class RequestPasswordReset extends Vue {
    readonly loading!: boolean;
    readonly errors!: string[];
    @Ref() readonly form!: VuetifyForm;

    readonly requestPasswordReset!: (payload: {
        email: string;
    }) => Promise<void>;

    @Watch("errors")
    readonly showError!: (error: string | string[]) => void;

    readonly emailData = new FieldData("Email address", {
        required: true,
        email: true
    });

    email = "";
    informationDialog = false;

    async submit(): Promise<void> {
        if (!this.form.validate()) return;

        await this.requestPasswordReset({
            email: this.email
        });

        this.informationDialog = true;
    }

    goToLoginPage(): void {
        this.$router.push(Routes.Login);
    }
}
