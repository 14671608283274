import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { NotificationsState } from ".";

export enum NotificationsGetters {
    IsShowing = "isShowing",
    NotificationMessage = "notificationMessage",
    Color = "color"
}

const getters: GetterTree<NotificationsState, RootState> = {
    [NotificationsGetters.IsShowing](state) {
        return state.isShowing;
    },
    [NotificationsGetters.NotificationMessage](state) {
        return state.notificationMessage;
    },
    [NotificationsGetters.Color](state) {
        return state.color;
    }
};

export default getters;
