import { CodeLevelData } from "@/models/CodeLevelData";
import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export interface CodeLevelState {
    data: CodeLevelData | null;
    isLoading: boolean;
    errors: string[] | null;
}

const state: CodeLevelState = {
    data: null,
    isLoading: false,
    errors: null
};

const codeLevel: Module<CodeLevelState, RootState> = {
    state: state,
    namespaced: true,
    mutations: mutations,
    getters: getters,
    actions: actions
};

export const codeLevelModule = createNamespacedHelpers("codeLevel");

export default codeLevel;
