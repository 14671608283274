
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import AuthCard from "@/components/AuthCard";
import TwoFactorForm from "@/components/TwoFactorForm";
import BaseDialog from "@/components/BaseDialog";
import logo from "@/assets/img/BMW_logo_(white).svg";
import playStoreLogo from "@/assets/img/play_store.png";
import appStoreLogo from "@/assets/img/app_store.png";
import { accountModule } from "@/store/modules/account";
import { AccountActions } from "@/store/modules/account/actions";
import { AccountGetters } from "@/store/modules/account/getters";
import Errors from "./BackendResponseErrors.json";
import { Routes } from "@/router/routes";
import { notificationsModule } from "@/store/modules/notifications";
import { NotificationsActions } from "@/store/modules/notifications/actions";

@Component({
    components: {
        AuthCard,
        TwoFactorForm,
        BaseDialog
    },
    computed: {
        ...accountModule.mapGetters({
            loading: AccountGetters.Loading,
            errors: AccountGetters.Errors
        })
    },
    methods: {
        ...accountModule.mapActions({
            getTwoFactorSetupCode: AccountActions.GetTwoFactorSetupCode,
            activateTwoFactor: AccountActions.ActivateTwoFactorAuthentication,
            verifyLogin: AccountActions.VerifyLogin
        }),
        ...notificationsModule.mapActions({
            showError: NotificationsActions.NotifyError
        })
    }
})
export default class Login extends Vue {
    private readonly logo: string = logo;
    private readonly appStoreLogo: string = appStoreLogo;
    private readonly playStoreLogo: string = playStoreLogo;

    private readonly loading!: boolean;
    private readonly errors!: string[] | null;

    private readonly getTwoFactorSetupCode!: (
        payload: string
    ) => Promise<string>;
    private readonly activateTwoFactor!: (payload: {
        token: string;
        code: string;
    }) => Promise<void>;
    private readonly verifyLogin!: () => Promise<void>;
    private readonly showError!: (error: string | string[]) => void;

    tokenErrorPopupVisible = false;
    successPopupVisible = false;

    async submit(twoFactorPassword: string): Promise<void> {
        const token = this.$route.query.token as string;
        await this.activateTwoFactor({
            token: token,
            code: twoFactorPassword
        });

        await this.verifyLogin();
        this.successPopupVisible = true;
    }

    mounted(): void {
        this.getQrCodeData()
            .then((data) => {
                const qrCodeImageElement = document.getElementById(
                    "qrCode"
                ) as HTMLImageElement;
                qrCodeImageElement.src = data;
            })
            .catch(() => (this.tokenErrorPopupVisible = true));
    }

    private async getQrCodeData(): Promise<string> {
        const token = this.$route.query.token as string;
        const data = await this.getTwoFactorSetupCode(token);
        return data;
    }

    goToLoginPage(): void {
        this.$router.push(Routes.Login);
    }

    onSuccess(): void {
        this.$router.push(
            (this.$route.query.redirecturl as string) ?? Routes.Home
        );
    }

    @Watch("errors")
    onErrors(errors: string[] | null): void {
        if (!errors) return;

        if (errors[0].includes(Errors.BadRequest.Error)) {
            this.showError(Errors.BadRequest.Message);
        } else {
            this.showError(errors[1] ?? Errors.Default.Message);
        }
    }
}
