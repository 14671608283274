import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { CasesState } from ".";

export enum CasesGetters {
    Info = "info",
    PaginationData = "paginationData",
    IsLoading = "isLoading",
    ArePresetsApplied = "arePresetsApplied",
    FavoriteCases = "favoriteCases"
}

const getters: GetterTree<CasesState, RootState> = {
    [CasesGetters.Info](state) {
        return state.info;
    },
    [CasesGetters.PaginationData](state) {
        return state.paginationData;
    },
    [CasesGetters.FavoriteCases](state) {
        return state.favoriteCases;
    },
    [CasesGetters.IsLoading](state) {
        return state.isLoading;
    },
    [CasesGetters.ArePresetsApplied](state) {
        return state.arePresetsApplied;
    }
};

export default getters;
