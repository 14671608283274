import dateFormat from "dateformat";

export function getYearMonthString(year: number, month: number): string {
    return dateFormat(new Date(year, month), "yyyy-mm");
}

export function getFullMonthYearString(year: number, month: number): string {
    return dateFormat(new Date(year, month), "mmmm yyyy");
}

export function formatToISODateTime(date: string): string {
    if (date.length == 10) return date + "T00:00:00Z";
    else if (date.length === 19 || date.length === 27) return date + "Z";
    else return date;
}
