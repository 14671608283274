
import Vue from "vue";
import Component from "vue-class-component";
import { postQualificationModule } from "@/store/modules/postQualification";
import { PostQualificationGetters } from "@/store/modules/postQualification/getters";
import { Routes } from "@/router/routes";

@Component({
    computed: {
        ...postQualificationModule.mapGetters({
            token: PostQualificationGetters.Token
        })
    }
})
export default class PostQualificationThanks extends Vue {
    readonly token!: string | null;

    changeData(): void {
        this.$router.push(Routes.PostQualification + this.token);
    }
}
