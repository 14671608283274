
import Vue from "vue";
import Component from "vue-class-component";

import { NotificationsGetters } from "@/store/modules/notifications/getters";
import { NotificationsMutation } from "@/store/modules/notifications/mutations";
import { notificationsModule } from "@/store/modules/notifications";

@Component({
    computed: {
        ...notificationsModule.mapGetters({
            isSnackbarShowing: NotificationsGetters.IsShowing,
            message: NotificationsGetters.NotificationMessage,
            color: NotificationsGetters.Color
        })
    },
    methods: {
        ...notificationsModule.mapMutations({
            setShowing: NotificationsMutation.SetShowing
        })
    }
})
export default class NotificationSnackbar extends Vue {}
