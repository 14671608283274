import { CaseInfo } from "@/models/cases/CaseInfo";
import { PaginationResult } from "@/models/PaginationResult";
import { MutationTree } from "vuex";
import { CasesState } from ".";
import { CaseListItem } from "@/models/cases/CaseListItem";

export enum CasesMutation {
    SetCases = "setCases",
    SetInfo = "setInfo",
    SetLoading = "setLoading",
    SetPaginationResult = "setPaginationResult",
    RemoveCase = "removeCase",
    SetInfoLoadingPromise = "setInfoLoadingPromise",
    SetArePresetsApplied = "setArePresetsApplied",
    SetFavoriteCases = "setFavoriteCases",
    MarkCaseAsFavorite = "markCaseAsFavorite",
    UnmarkCaseAsFavorite = "unmarkCaseAsFavorite"
}

const mutations: MutationTree<CasesState> = {
    [CasesMutation.SetPaginationResult](
        state,
        payload: PaginationResult<CaseListItem>
    ) {
        state.paginationData = payload;
    },
    [CasesMutation.SetInfo](state, payload: CaseInfo) {
        state.info = payload;
    },
    [CasesMutation.SetLoading](state, payload: boolean) {
        state.isLoading = payload;
    },
    [CasesMutation.RemoveCase](state, payload: number) {
        if (state.paginationData) {
            state.paginationData.items = state.paginationData.items.filter(
                (c) => c.caseId !== payload
            );
        }
    },
    [CasesMutation.SetInfoLoadingPromise](
        state,
        payload: Promise<void> | null
    ) {
        state.infoLoadingPromise = payload;
    },
    [CasesMutation.SetArePresetsApplied](state, payload: boolean) {
        state.arePresetsApplied = payload;
    },
    [CasesMutation.SetFavoriteCases](state, payload: number[]) {
        state.favoriteCases = payload;
    },
    [CasesMutation.MarkCaseAsFavorite](state, payload: number) {
        state.favoriteCases.push(payload);
    },
    [CasesMutation.UnmarkCaseAsFavorite](state, payload: number) {
        state.favoriteCases = state.favoriteCases.filter((c) => c !== payload);
    }
};

export default mutations;
