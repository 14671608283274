
import Vue from "vue";
import { Component, Ref, Prop } from "vue-property-decorator";

import { Routes } from "@/router/routes";
import FieldData from "@/services/fieldData";
import { VuetifyForm } from "@/models/VuetifyForm";

@Component
export default class CredentialsForm extends Vue {
    @Prop(Boolean)
    readonly loading!: boolean;

    @Ref() readonly form!: VuetifyForm;

    readonly emailData = new FieldData("Email-Address", {
        required: true,
        email: true
    });

    readonly passwordData = new FieldData("Password", {
        required: true,
        minLength: 6,
        maxLength: 40
    });

    email = "";
    password = "";

    resetPassword(): void {
        this.$router.push(Routes.RequestPasswordReset);
    }

    submit(): void {
        if (this.form.validate())
            this.$emit("submit", this.email, this.password);
    }
}
