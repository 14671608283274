import {
    isUserAuthorized,
    isUserInRoles,
    isUserInRoleTypes,
    userHasClaims
} from "@/services/userUtils";
import { Routes } from "@/router/routes";
import VueRouter from "vue-router";
import store from "@/store";
import { RouteMutation } from "@/store/modules/route/mutations";
import { routeStoreNamespace } from "@/store/modules/route";

const title = process.env.VUE_APP_TITLE;

export function setupRouterHooks(router: VueRouter): void {
    setupAuthorizationCheck(router);
    setupTitleChange(router);
    setupPreviousRouteSetting(router);
}

function setupTitleChange(router: VueRouter): void {
    router.beforeEach((to, from, next) => {
        document.title = to.meta?.title ? `${title} - ${to.meta.title}` : title;
        next();
    });
}

function setupAuthorizationCheck(router: VueRouter): void {
    router.beforeEach((to, from, next) => {
        if (to.meta && !to.meta.allowAnonymous) {
            if (!isUserAuthorized()) {
                next(Routes.Login);
                return;
            }
            if (to.meta.allowedRoles && !isUserInRoles(to.meta.allowedRoles)) {
                next(Routes.Login);
                return;
            }
            if (
                to.meta.allowedRoleTypes &&
                !isUserInRoleTypes(to.meta.allowedRoleTypes)
            ) {
                next(Routes.Login);
                return;
            }
            if (to.meta.claims && !userHasClaims(to.meta.claims)) {
                next(Routes.Login);
                return;
            }
        }

        next();
    });
}

function setupPreviousRouteSetting(router: VueRouter): void {
    router.beforeEach((to, from, next) => {
        store.commit(
            `${routeStoreNamespace}/${RouteMutation.SetPreviousRoute}`,
            from
        );
        next();
    });
}
