import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { MarketState } from ".";

export enum MarketGetters {
    Markets = "markets",
    Market = "market",
    Info = "info",
    IsLoading = "isLoading",
    Regions = "regions",
    Countries = "countries",
    Errors = "errors"
}

const getters: GetterTree<MarketState, RootState> = {
    [MarketGetters.Markets](state) {
        return state.markets;
    },
    [MarketGetters.Market](state) {
        return state.market;
    },
    [MarketGetters.Info](state) {
        return state.info;
    },
    [MarketGetters.IsLoading](state) {
        return state.loading;
    },
    [MarketGetters.Regions](state) {
        return state.regions;
    },
    [MarketGetters.Errors](state) {
        return state.errors;
    },
    [MarketGetters.Countries](state) {
        return state.countries;
    }
};

export default getters;
