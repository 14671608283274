
import Vue from "vue";
import { Component } from "vue-property-decorator";
import reportApi from "@/api/reportApi";

@Component({})
export default class YtdReport extends Vue {
    protected reportOutdated = false;
    protected reportDate: Date = new Date();

    created(): void {
        reportApi.getYearToDateReportDatetime().then((response) => {
            this.reportDate = new Date(response.data.reportCreatedDateTime);
            const acceptableDate = new Date();
            acceptableDate.setDate(acceptableDate.getDate() - 2);
            this.reportOutdated =
                this.reportDate.getTime() < acceptableDate.getTime();
        });
    }
}
