import { CodeLevelData } from "@/models/CodeLevelData";
import { MutationTree } from "vuex";
import { CodeLevelState } from ".";

export enum CodeLevelMutations {
    SetData = "setData",
    SetErrors = "setErrors",
    SetLoading = "setLoading"
}

const mutations: MutationTree<CodeLevelState> = {
    [CodeLevelMutations.SetData](state, payload: CodeLevelData | null): void {
        state.data = payload;
    },
    [CodeLevelMutations.SetErrors](state, payload: string[] | null): void {
        state.errors = payload;
    },
    [CodeLevelMutations.SetLoading](state, payload: boolean): void {
        state.isLoading = payload;
    }
};

export default mutations;
