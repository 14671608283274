import codeLevelApi from "@/api/codeLevelApi";
import { makeStoreRequest } from "@/services/requestUtils";
import { RootState } from "@/store";
import { AxiosResponse } from "axios";
import { ActionTree, Commit } from "vuex";
import { CodeLevelState } from ".";
import { CodeLevelMutations } from "./mutations";

export enum CodeLevelActions {
    LoadCodeLevels = "loadCodeLevels"
}

async function makeModuleRequest<T>(
    commit: Commit,
    callback: () => Promise<AxiosResponse<T>>
) {
    return makeStoreRequest(commit, callback, CodeLevelMutations.SetLoading);
}

const actions: ActionTree<CodeLevelState, RootState> = {
    async [CodeLevelActions.LoadCodeLevels]({ commit }) {
        const codeLevelData = await makeModuleRequest(commit, () =>
            codeLevelApi.getCodeLevelData()
        );
        commit(CodeLevelMutations.SetData, codeLevelData);
    }
};

export default actions;
