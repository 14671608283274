import { VuetifyThemeVariant } from "vuetify/types/services/theme";

// Colors from scss/variables.scss

const theme: Partial<VuetifyThemeVariant> = {
    primary: "#1b69d4",
    secondary: "#666666",
    error: "#dc3545",
    success: "#008b29",
    warning: "#fccc02"
};

export default theme;
