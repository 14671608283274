import { MutationTree } from "vuex";
import { PostQualificationState } from ".";

export enum PostQualificationMutation {
    SetToken = "setToken"
}

const mutations: MutationTree<PostQualificationState> = {
    [PostQualificationMutation.SetToken](state, payload: string | null) {
        state.token = payload;
    }
};

export default mutations;
