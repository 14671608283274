import { Session } from "@/models/Session";
import { LoginResult } from "@/models/LoginResult";
import api from ".";
import { AxiosResponse } from "axios";
import { User } from "@/models/users/User";

async function logUserIn(
    username: string,
    password: string
): Promise<AxiosResponse<LoginResult>> {
    if (username.length == 0 || password.length == 0) {
        throw "Username or password null or empty";
    }

    const formData = new FormData();

    formData.append("username", username);
    formData.append("password", password);

    const response: AxiosResponse<LoginResult> = await api.post(
        "/user/login",
        formData
    );

    if (response.data.finishedSuccessfully == true) {
        const userResponse: AxiosResponse<User> = await api.get("/user");
        response.data = {
            finishedSuccessfully: true,
            isMultiFactorRequired: false,
            session: {
                User: userResponse.data,
                ValidUntil: Date.now() + 60000 // 60 seconds
            }
        };
    }

    return response;
}

async function logUserInTwoFactor(
    twoFactorPassword: string
): Promise<AxiosResponse<Session>> {
    const formData = new FormData();

    formData.append("totp", twoFactorPassword);

    const response = await api.post("/user/login/2fa", formData);

    if (response.data.finishedSuccessfully == true) {
        const userResponse: AxiosResponse<User> = await api.get("/user");
        response.data = {
            User: userResponse.data,
            ValidUntil: Date.now() + 60000 // 60 seconds
        };
    }

    return response;
}

async function verifyLogin(): Promise<AxiosResponse<Session>> {
    const response = await api.get("/user");
    response.data = {
        User: response.data,
        ValidUntil: Date.now() + 60000 // 60 seconds
    };
    return response;
}

async function logUserOut(): Promise<AxiosResponse<void>> {
    return await api.post("/user/logout");
}

async function requestPasswordReset(
    email: string
): Promise<AxiosResponse<void>> {
    const formData = new FormData();
    formData.append("emailaddress", email);
    return api.post("/user/password/request", formData);
}

async function resetPassword(
    token: string,
    newPassword: string
): Promise<AxiosResponse<void>> {
    const formData = new FormData();
    formData.append("newPassword", newPassword);
    return api.post("/user/password/reset?token=" + token, formData);
}

async function activateAccount(
    token: string,
    password: string
): Promise<AxiosResponse<string>> {
    const formData = new FormData();
    formData.append("password", password);
    return api.post("/user/activate?token=" + token, formData);
}

async function getTwoFactorResetToken(
    userName: string,
    recoveryCode: string
): Promise<AxiosResponse<string>> {
    const formData = new FormData();
    formData.append("recoveryCode", recoveryCode);
    return await api.post(`/user/${userName}/2fa/recovery`, formData);
}

async function getTwoFactorSetupCode(
    token: string
): Promise<AxiosResponse<string>> {
    const response = await api.get("2fa/recovery?token=" + token, {
        responseType: "arraybuffer"
    });
    const image = btoa(
        new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
        )
    );
    response.data = `data:${response.headers[
        "content-type"
    ].toLowerCase()};base64,${image}`;
    return response;
}

async function activateTwoFactorAuthentication(
    token: string,
    code: string
): Promise<AxiosResponse<string>> {
    const formData = new FormData();
    formData.append("code", code);
    return api.post("/2fa/code?token=" + token, formData);
}

export default {
    logUserIn,
    logUserInTwoFactor,
    verifyLogin,
    requestPasswordReset,
    resetPassword,
    activateAccount,
    logUserOut,
    getTwoFactorResetToken,
    getTwoFactorSetupCode,
    activateTwoFactorAuthentication
};
