import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export interface NotificationsState {
    isShowing: boolean;
    notificationMessage: string;
    color: string;
}

const state: NotificationsState = {
    isShowing: false,
    notificationMessage: "",
    color: ""
};

const moduleState: Module<NotificationsState, RootState> = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};

export const notificationsStoreNamespace = "notifications";

export const notificationsModule = createNamespacedHelpers(
    notificationsStoreNamespace
);

export default moduleState;
