import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import getters from "./getters";
import mutations from "./mutations";

export interface PostQualificationState {
    token: string | null;
}

const state: PostQualificationState = {
    token: null
};

const postQualification: Module<PostQualificationState, RootState> = {
    namespaced: true,
    state,
    getters,
    mutations
};

export const postQualificationModule =
    createNamespacedHelpers("postQualification");

export default postQualification;
