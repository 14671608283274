import { PaginationResult } from "@/models/PaginationResult";
import { Blacklist } from "@/models/blacklist/Blacklist";
import { createNamespacedHelpers, Module } from "vuex";
import { RootState } from "@/store";
import actions from "@/store/modules/blacklist/actions";
import getters from "@/store/modules/blacklist/getters";
import mutations from "@/store/modules/blacklist/mutations";

export interface BlacklistState {
    blacklists: Blacklist[];
    blacklist: Blacklist | null;
    errors: string[] | null;
    loading: boolean;
    paginationData: PaginationResult<Blacklist>;
}

const blacklistState: BlacklistState = {
    blacklists: [],
    blacklist: null,
    errors: [],
    loading: false,
    paginationData: {
        totalCount: 0,
        items: []
    }
};

const blacklist: Module<BlacklistState, RootState> = {
    namespaced: true,
    state: blacklistState,
    actions: actions,
    getters: getters,
    mutations: mutations
};

export const blacklistModule = createNamespacedHelpers("blacklist");

export default blacklist;
