import { MutationTree } from "vuex";
import { ScrollState } from "./index";

export enum ScrollMutation {
    SetScrollPosition = "setScrollPosition"
}

const mutations: MutationTree<ScrollState> = {
    [ScrollMutation.SetScrollPosition](
        state,
        { key, number }: { key: string; number: number }
    ) {
        state.positions.set(key, number);
    }
};

export default mutations;
