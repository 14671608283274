import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { ContactState } from ".";

export enum ContactGetters {
    Contacts = "contacts",
    Contact = "contact",
    IsLoading = "isLoading",
    PaginationData = "paginationData",
    Errors = "errors"
}

const getters: GetterTree<ContactState, RootState> = {
    [ContactGetters.Contacts](state) {
        return state.contacts;
    },
    [ContactGetters.Contact](state) {
        return state.contact;
    },
    [ContactGetters.PaginationData](state) {
        return state.paginationData;
    },
    [ContactGetters.IsLoading](state) {
        return state.loading;
    },
    [ContactGetters.Errors](state) {
        return state.errors;
    }
};

export default getters;
