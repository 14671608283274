import validationRules, {
    RulesSettings,
    Rule
} from "@/services/validationRules";

export default class FieldData {
    private _label: string;
    private _rules: Array<Rule>;

    constructor(label: string, ruleSettings: RulesSettings) {
        this._label = ruleSettings.required ? label + "* " : label;
        this._rules = validationRules.getRules(ruleSettings, label);
    }

    get label(): string {
        return this._label;
    }

    get rules(): Array<Rule> {
        return this._rules;
    }
}
