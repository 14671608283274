import { render, staticRenderFns } from "./CredentialsForm.html?vue&type=template&id=5d2046a8&"
import script from "./CredentialsForm.vue?vue&type=script&lang=ts&"
export * from "./CredentialsForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports