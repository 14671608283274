import { Case } from "@/models/cases/Case";
import { AddMoreDaysDTO } from "@/models/solution/AddMoreDaysDTO";
import { ComplexSolveDTO } from "@/models/solution/ComplexSolveDTO";
import { SimpleSolveDTO } from "@/models/solution/SimpleSolveDTO";
import { AxiosResponse } from "axios";
import api from ".";

function getSolutionCase(
    token: string,
    marketStatus: string
): Promise<AxiosResponse<Case>> {
    return api.get<Case>("solution/case", { params: { token, marketStatus } });
}

function addMoreDays(data: AddMoreDaysDTO): Promise<AxiosResponse<Date>> {
    return api.post<Date>("solution/add-more-days", data);
}

function simpleSolve(data: SimpleSolveDTO): Promise<AxiosResponse<void>> {
    return api.post("solution/simple-solve", data);
}

function complexSolve(data: ComplexSolveDTO): Promise<AxiosResponse<number>> {
    return api.post("solution/complex-solve", data);
}

function uploadAttachmentToComplexSolve(
    token: string,
    caseEventId: number,
    file: File
): Promise<AxiosResponse<void>> {
    const formData = new FormData();
    formData.append("token", token);
    formData.append("caseEventId", caseEventId.toString());
    formData.append("attachment", file);
    return api.put("solution/complex-solve/attachment", formData);
}

export default {
    getSolutionCase,
    addMoreDays,
    simpleSolve,
    complexSolve,
    uploadAttachmentToComplexSolve
};
