
import { Case } from "@/models/cases/Case";
import { VuetifyForm } from "@/models/VuetifyForm";
import { solutionModule } from "@/store/modules/solution";
import { SolutionGetters } from "@/store/modules/solution/getters";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import BaseButton from "@/components/BaseButton";

@Component({
    components: {
        BaseButton
    },
    computed: {
        ...solutionModule.mapGetters({
            loading: SolutionGetters.Loading
        })
    }
})
export default class SimpleCaseSolution extends Vue {
    protected readonly loading!: boolean;

    @Prop({ type: Object, required: true })
    public readonly caseModel!: Case;

    protected solution = "";

    $refs!: {
        form: VuetifyForm;
    };

    submit(): void {
        const result = this.$refs.form.validate();
        if (result) {
            this.$emit("solve", this.solution);
        }
    }
}
