import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { MonthBillingData } from "@/models/billing/MonthBillingData";

export interface BillingState {
    monthBillingData: MonthBillingData[];
    isLoading: boolean;
    errors: string[];
    year: number;
    month: number;
}

const state: BillingState = {
    monthBillingData: [],
    isLoading: false,
    errors: [],
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1
};

const billingModuleState: Module<BillingState, RootState> = {
    namespaced: true,
    state,
    actions: actions,
    getters: getters,
    mutations: mutations
};

export const billingModule = createNamespacedHelpers("billing");

export default billingModuleState;
