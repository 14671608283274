import { Contact } from "@/models/cases/Contact";
import { MutationTree } from "vuex";
import { ContactState } from ".";
import { ContactCar } from "@/models/cases/ContactCar";
import { PaginationResult } from "@/models/PaginationResult";
import { ContactListDto } from "@/models/Gdpr/ContactListDto";

export enum ContactMutation {
    SetContacts = "setContacts",
    SetContact = "setContact",
    SetLoading = "setLoading",
    SetErrors = "setErrors",
    SetPaginationResult = "setPaginationResult",
    RemoveContact = "removeContact",
    AddContactCar = "addContactCar",
    RemoveContactCar = "removeContactCar",
    SetContactCar = "setContactCar"
}

const mutations: MutationTree<ContactState> = {
    [ContactMutation.SetPaginationResult](
        state,
        payload: PaginationResult<ContactListDto>
    ) {
        state.paginationData = payload;
    },
    [ContactMutation.SetContacts](state, payload: Contact[]) {
        state.contacts = payload;
    },
    [ContactMutation.SetContact](state, payload: Contact | null) {
        state.contact = payload;
    },
    [ContactMutation.SetLoading](state, payload: boolean) {
        state.loading = payload;
    },
    [ContactMutation.SetErrors](state, payload: string[]) {
        state.errors = payload;
    },
    [ContactMutation.AddContactCar](
        state,
        payload: { car: ContactCar; contactCarId: number }
    ) {
        payload.car.contactCarId = payload.contactCarId;
        if (state.contact?.cars && payload) {
            state.contact.cars.push(payload.car);
        }
    },
    [ContactMutation.SetContactCar](
        state,
        payload: { contactId: number; contactCar: ContactCar }
    ) {
        if (state.contact?.cars) {
            state.contact.cars = state.contact.cars.map((item) => {
                if (item.contactCarId === payload.contactCar.contactCarId) {
                    item = payload.contactCar;
                }
                return item;
            });
        }
    },
    [ContactMutation.RemoveContact](state, payload: number) {
        if (state.paginationData) {
            state.paginationData.items = state.paginationData.items.filter(
                (c) => c.id !== payload
            );
            if (state.contact?.contactId === payload) {
                state.contact = null;
            }
        }
    },
    [ContactMutation.RemoveContactCar](
        state,
        payload: { contactId: number; contactCarId: number }
    ) {
        if (
            state.contact?.cars &&
            state.contact.contactId === payload.contactId
        ) {
            state.contact.cars = state.contact.cars.filter(
                (c) => c.contactCarId !== payload.contactCarId
            );
        }
    }
};

export default mutations;
