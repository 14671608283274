import { createNamespacedHelpers, Module } from "vuex";
import { RootState } from "@/store";
import actions from "./actions";
import mutations from "./mutations";

export interface ScrollState {
    positions: Map<string, number>;
}

const state: ScrollState = {
    positions: new Map<string, number>()
};

const scroll: Module<ScrollState, RootState> = {
    namespaced: true,
    state,
    actions,
    mutations
};

export const scrollModule = createNamespacedHelpers("scroll");

export default scroll;
