import { PaginationInfo } from "@/models/PaginationInfo";
import { PaginationResult } from "@/models/PaginationResult";
import { Rule } from "@/models/rules/Rule";
import { RuleInfo } from "@/models/rules/RuleInfo";
import { AxiosResponse } from "axios";
import api from ".";

function getRules(
    info: PaginationInfo,
    ruleTypeId?: number,
    marketId?: number
): Promise<AxiosResponse<PaginationResult<Rule>>> {
    return api.get<PaginationResult<Rule>>("rule", {
        params: { ...info, ruleTypeId, marketId }
    });
}

function getRuleById(ruleId: number): Promise<AxiosResponse<Rule>> {
    return api.get<Rule>(`rule/${ruleId}`);
}

function createRule(rule: Rule): Promise<AxiosResponse<Rule>> {
    return api.post<Rule>("rule", rule);
}

function updateRule(rule: Rule): Promise<AxiosResponse<Rule>> {
    return api.put<Rule>(`rule/${rule.ruleId}`, rule);
}

function deleteRule(ruleId: number): Promise<AxiosResponse<void>> {
    return api.delete(`rule/${ruleId}`);
}

function getInfo(): Promise<AxiosResponse<RuleInfo>> {
    return api.get<RuleInfo>("rule/info");
}

export default {
    getRules,
    getRuleById,
    createRule,
    updateRule,
    deleteRule,
    getInfo
};
