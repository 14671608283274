import { AxiosError, AxiosResponse } from "axios";
import { Commit } from "vuex";
import { getErrorTextFromResponse } from "./responseErrorUtils";

export async function makeRequest<T>(
    callback: () => Promise<AxiosResponse<T>>,
    changeLoading: (value: boolean) => void
): Promise<T> {
    try {
        changeLoading(true);
        const result = await callback();
        return result.data;
    } catch (thrownError) {
        const axiosError = thrownError as AxiosError;
        if (axiosError.isAxiosError) {
            axiosError.message = axiosError.response
                ? getErrorTextFromResponse(axiosError.response)
                : "Network error";
        }

        throw axiosError;
    } finally {
        changeLoading(false);
    }
}

export async function makeStoreRequest<T>(
    commit: Commit,
    callback: () => Promise<AxiosResponse<T>>,
    loadingMutation: string
): Promise<T> {
    return await makeRequest(callback, (value) =>
        commit(loadingMutation, value)
    );
}

export async function saveAttachment(
    id: number,
    attachments: File[],
    removeAttachmentsId: number[],
    addAttachment: (mid: number, value: File) => Promise<AxiosResponse>,
    removeAttachment: (id: number, value: number) => Promise<AxiosResponse>
): Promise<void> {
    await attachmentRequest(id, attachments, addAttachment);
    await attachmentRequest(id, removeAttachmentsId, removeAttachment);
}

async function attachmentRequest<T>(
    id: number,
    attachments: T[],
    request: (marketId: number, value: T) => Promise<AxiosResponse>
) {
    if (attachments.length) {
        const requests: Promise<AxiosResponse>[] = [];
        attachments.forEach((a) => {
            requests.push(request(id, a));
        });
        await Promise.all(requests);
    }
}
