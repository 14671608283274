import { MonthlyReport } from "@/models/reports/MonthlyReport";
import { MutationTree } from "vuex";
import { MonthlyReportState } from ".";

export enum MonthlyReportMutation {
    SetMonthlyReport = "setMonthlyReport",
    SetLoading = "setLoading",
    SetErrors = "setErrors"
}

const mutations: MutationTree<MonthlyReportState> = {
    [MonthlyReportMutation.SetMonthlyReport](
        state,
        payload: MonthlyReport | null
    ) {
        state.monthlyReport = payload;
    },
    [MonthlyReportMutation.SetLoading](state, payload: boolean) {
        state.loading = payload;
    },
    [MonthlyReportMutation.SetErrors](state, payload: string[]) {
        state.errors = payload;
    }
};

export default mutations;
