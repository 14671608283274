import { AxiosResponse } from "axios";
import api from "@/api/index";
import { DailyReportType } from "@/models/reports/DailyReportType";
import { YearToDateMetadata } from "@/models/reports/YearToDateMetadata";
import { User } from "@/models/users/User";

function getDailyReport(
    date: Date | string
): Promise<AxiosResponse<DailyReportType[]>> {
    return api.get("cases/reporting/daily", { params: { date: date } });
}

function setReportUsers(users: number[]): Promise<AxiosResponse> {
    return api.post("cases/reporting/daily/report-users", { users });
}

function getReportUsers(): Promise<AxiosResponse<User[]>> {
    return api.get("cases/reporting/daily/report-users");
}

function getYearToDateReportDatetime(): Promise<
    AxiosResponse<YearToDateMetadata>
> {
    return api.get("cases/reporting/ytd/metadata");
}

export default {
    getDailyReport,
    setReportUsers,
    getReportUsers,
    getYearToDateReportDatetime
};
